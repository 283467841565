import { IInUserIdentity } from 'app/shared/model/in-user-identity.model';
import { IUser } from 'app/shared/model/user.model';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IReconSessionUserIdentitySystemsNotInIam {
  id?: number;
  applicationCode?: string | null;
  userBusinessKey?: string | null;
  userName?: string | null;
  processed?: boolean | null;
  comments?: string | null;
  inUserIdentity?: IInUserIdentity | null;
  processedBy?: IUser | null;
  importSession?: IImportSession | null;
}

export const defaultValue: Readonly<IReconSessionUserIdentitySystemsNotInIam> = {
  processed: false,
};
