import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrder } from 'app/shared/model/order.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { IRoleDefinition } from 'app/shared/model/role-definition.model';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { IApplication } from './application.model';
import { IUserIdentityRole } from './user-identity-role.model';

export interface IOrderedRole {
  id?: number;
  description?: string | null;
  grantedBy?: PermissionGrantLevel | null;
  executionStatus?: OrderExecutionStatus | null;
  executionResult?: string | null;
  scheduleTime?: string | null;
  executionStartTime?: string | null;
  executionEndTime?: string | null;
  executionEstimatedDuration?: number | null;
  executionActualDuration?: number | null;
  businessValidityStart?: string | null;
  businessValidityEnd?: string | null;
  returnedBusinessValidityStart?: string | null;
  returnedBusinessValidityEnd?: string | null;
  order?: IOrder | null;
  userIdentity?: IUserIdentity | null;
  orderedUserIdentity?: IOrderedUserIdentity | null;
  roleDefinition?: IRoleDefinition | null;
  dependsOn?: IOrderedRole | null;
  operationType?: string | null;
  application?: IApplication | null;
  first?: boolean;
  relatedUserRole?: IUserIdentityRole | null;
  originalOrderedRole?: IOrderedRole | null;
}

export const defaultValue: Readonly<IOrderedRole> = {};
