import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { IProfileUserIdentityRole } from 'app/shared/model/profile-user-identity-role.model';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { generateAssignedRoleUrl } from './profile-users';

interface IAssignedProfileRolesTablesProps {
  profileRoles: IProfileUserIdentityRole[];
}

const AssignedProfileRolesTable = ({ profileRoles }: IAssignedProfileRolesTablesProps) => {
  return (
    <div className="table-responsive">
      {profileRoles.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Translate contentKey="iamdentityApp.profileUserIdentityRole.roleDefinition">Role Definition</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.profileUserIdentityRole.roleDefinitionExpression">
                  Role Definition Expression
                </Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.profileUserIdentityRole.descriptionExpression">Description Expression</Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {profileRoles.map((profileRole, i) => (
              <tr key={`role-${i}`}>
                <td>{profileRole.roleDefinition ? profileRole.roleDefinition.name : ''}</td>
                <td>
                  {profileRole.roleDefinitionExpression ? <CompactExpressionBuilder tree={profileRole.roleDefinitionExpression} /> : null}
                </td>
                <td>{profileRole.descriptionExpression ? <CompactExpressionBuilder tree={profileRole.descriptionExpression} /> : null}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={generateAssignedRoleUrl(profileRole.profileUserIdentity, profileRole.id, 'VIEW')}
                      className="btn-md"
                      color="info"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />
                    </Button>
                    <Button
                      tag={Link}
                      to={generateAssignedRoleUrl(profileRole.profileUserIdentity, profileRole.id)}
                      className="btn-md"
                      color="primary"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                    <Button
                      className="btn-md"
                      tag={Link}
                      to={generateAssignedRoleUrl(profileRole.profileUserIdentity, profileRole.id, 'DELETE')}
                      color="danger"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="alert alert-warning">
          <Translate contentKey="iamdentityApp.profileUserIdentityRole.home.notFound">No Profile User Identity Roles found</Translate>
        </div>
      )}
    </div>
  );
};

export default AssignedProfileRolesTable;
