import { IConnectorCommand } from 'app/shared/model/connector-command.model';
import { IIAMSystem } from 'app/shared/model/iam-system.model';
import { IApplication } from 'app/shared/model/application.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { IOrderedRole } from 'app/shared/model/ordered-role.model';
import { IOrderedGroup } from 'app/shared/model/ordered-group.model';
import { IOrderedSpecificPermission } from 'app/shared/model/ordered-specific-permission.model';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { ToaExecutionStage } from 'app/shared/model/enumerations/toa-execution-stage.model';
import { IOrder } from './order.model';

export interface ITechnicalOrderAction {
  id?: number;
  executionStatus?: OrderExecutionStatus | null;
  executionStartTime?: string | null;
  executionEndTime?: string | null;
  executedCommand?: string | null;
  executionResult?: string | null;
  priority?: number | null;
  connectorCommand?: IConnectorCommand | null;
  iamSystem?: IIAMSystem | null;
  application?: IApplication | null;
  orderedUserIdentity?: IOrderedUserIdentity | null;
  orderedRole?: IOrderedRole | null;
  orderedGroup?: IOrderedGroup | null;
  orderedSpecificPermission?: IOrderedSpecificPermission | null;
  order?: IOrder | null;
  rawConnectorLog?: string | null;
  executionStage?: ToaExecutionStage | null;
}

export const defaultValue: Readonly<ITechnicalOrderAction> = {};
