import { BPMType } from './enumerations/bpm-type.model';
import { TaskStatus } from './enumerations/task-status.model';
import { IOrder } from './order.model';
import { IOrderedSpecificPermission } from './ordered-specific-permission.model';
import { IOrderedUserIdentity } from './ordered-user-identity.model';
import { ITechnicalOrderAction } from './technical-order-action.model';
import { IUserIdentity } from './user-identity.model';

export enum TaskNames {
  Aproba_Level_1 = 'Aproba_Level_1',
  Aproba_Level_2 = 'Aproba_Level_2',
  Aproba_Level_3 = 'Aproba_Level_3',
  Revizuieste_Cererea = 'Revizuieste_Cererea',
}

export enum FormBusinessGroup {
  ConnectorCommandAttributeDTO = 'ConnectorCommandAttributeDTO',
  OrderedUserIdentityDTO = 'OrderedUserIdentityDTO',
  OrderedRoleDTO = 'OrderedRoleDTO',
  OrderedGroupDTO = 'OrderedGroupDTO',
  OrderedSpecificPermissionDTO = 'OrderedSpecificPermissionDTO',
}

export enum FormHeaderProperties {
  executionStage = 'executionStage',
  executedCommand = 'executedCommand',
  responseMessage = 'responseMessage',
}

export interface ITask {
  id: string;
  assignee: string;
  name: TaskNames;
  owner: string;
  description: string;
  createdDate: Date;
  lastUpdated: Date;
  priority: number;
  processDefinitionId: string;
  processDefinitionKey: BPMType;
  processInstanceId: string;
  status: TaskStatus;
  formKey: string;
  businessKey: string;
  standalone: boolean;
  taskDefinitionKey: string;
  caseInstanceId: string;
  caseExecutionId: string;
  caseDefinitionId: string;
  executionId: string;
  tenantId: string;
  createTime: Date;
  followUpDate: Date;
  suspended: boolean;
}

interface ITaskGroupValues {
  direction: string | null;
  name: string;
  value: string | null;
  valueType: 'number' | 'string' | 'boolean' | 'integer' | 'long' | 'date' | 'datetime' | 'instant';
}

interface ITaskGroupForm {
  id: number;
  name: string;
  values: ITaskGroupValues[];
}

interface ITaskBusinessFormHeaderProperties {
  direction: any;
  name: string;
  value: string;
  valueType: string;
}

export interface ITaskBusinessForm {
  header: {
    properties: ITaskBusinessFormHeaderProperties[];
  };
  groups: ITaskGroupForm[];
  name: string;
}

export interface IOrderTask extends ITask {
  order: IOrder;
}

export interface IToaTask extends ITask {
  toa: ITechnicalOrderAction;
  businessForm: ITaskBusinessForm;
  order: IOrder;
}

export enum VariableNames {
  lvl1Approved = 'lvl1Approved',
  lvl2Approved = 'lvl2Approved',
  lvl3Approved = 'lvl3Approved',
  canceled = 'canceled',
  retry = 'retry',
  escalate = 'escalate',
  admin_retry = 'admin_retry',
  is_cancelled = 'is_cancelled',
}

export enum VariableValueTypes {
  boolean = 'boolean',
}

export enum TaskTypes {
  Resolve_Toa = 'Resolve_Toa',
  Resolve_Toa_Admin = 'Resolve_Toa_Admin',
  Resolve_Toa_Timeout = 'Resolve_Toa_Timeout',
  Resolve_Toa_Timeout_Admin = 'Resolve_Toa_Timeout_Admin',
}

export interface CompleteTask {
  businessForm?: ITaskBusinessForm;
  variables: {
    name: VariableNames[];
    value: any[];
    valueType: VariableValueTypes[];
  };
}

export const defaultValue: Readonly<ITask> = {
  id: '',
  assignee: '',
  name: TaskNames.Aproba_Level_1,
  owner: '',
  createdDate: null,
  lastUpdated: null,
  priority: null,
  processDefinitionId: '',
  processDefinitionKey: BPMType.Process_Order_Approval,
  processInstanceId: '',
  status: TaskStatus.CREATED,
  formKey: '',
  businessKey: null,
  standalone: false,
  taskDefinitionKey: '',
  caseInstanceId: '',
  caseExecutionId: '',
  caseDefinitionId: '',
  executionId: '',
  tenantId: '',
  createTime: null,
  followUpDate: null,
  suspended: null,
  description: '',
};
