import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown, NavDropdownHover } from './menu-components';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import MenuItem from './menu-item';
import { faUsersGear } from '@fortawesome/free-solid-svg-icons';

export const UserAccessData = () => (
  <NavDropdown icon={faUsersGear} name={translate('global.menu.usersAndRoles')} id="entity-menu" data-cy="entity" className="max-height-80">
    <DropdownItem className="menu-header" header>
      <Translate contentKey="global.menu.usersAndRoles" />
    </DropdownItem>
    <MenuItem icon="asterisk" to="/user-identity">
      <Translate contentKey="global.menu.entities.userIdentity" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-group">
      <Translate contentKey="global.menu.entities.userGroup" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-identity-role">
      <Translate contentKey="global.menu.entities.userIdentityRole" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/specific-permission">
      <Translate contentKey="global.menu.entities.specificPermission" />
    </MenuItem>
    <DropdownItem className="d-inline-block submenu dropdown-custom" toggle={false} tag="span">
      <NavDropdown
        icon="th-list"
        name={translate('global.menu.usersAndRolesExt')}
        id="hr-data-target"
        data-cy="entity"
        direction="right"
        className="max-height-80 overflow-a bgc-custom"
      >
        <MenuItem icon="asterisk" to="/import-session">
          <Translate contentKey="global.menu.entities.importSession" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/in-user-identity">
          <Translate contentKey="global.menu.entities.inUserIdentity" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/in-user-identity-role">
          <Translate contentKey="global.menu.entities.inUserIdentityRole" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/in-user-group">
          <Translate contentKey="global.menu.entities.inUserGroup" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/in-specific-permission">
          <Translate contentKey="global.menu.entities.inSpecificPermission" />
        </MenuItem>
      </NavDropdown>
    </DropdownItem>
    <DropdownItem className="d-inline-block submenu dropdown-custom" toggle={false} tag="span">
      <NavDropdown
        icon="th-list"
        name={translate('global.menu.usersAndRolesReconciliation')}
        id="hr-data-reconciliation"
        data-cy="entity"
        direction="right"
        className="max-height-80 overflow-a bgc-custom"
      >
        <MenuItem icon="asterisk" to="/recon-session-user-identity-iam-not-in-systems">
          <Translate contentKey="global.menu.entities.reconSessionUserIdentityIamNotInSystems" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/recon-session-user-identity-role-iam-not-in-systems">
          <Translate contentKey="global.menu.entities.reconSessionUserIdentityRoleIamNotInSystems" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/recon-session-user-group-iam-not-in-systems">
          <Translate contentKey="global.menu.entities.reconSessionUserGroupIamNotInSystems" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/recon-session-user-permission-iam-not-in-systems">
          <Translate contentKey="global.menu.entities.reconSessionUserPermissionIamNotInSystems" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/recon-session-user-identity-systems-not-in-iam">
          <Translate contentKey="global.menu.entities.reconSessionUserIdentitySystemsNotInIam" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/recon-session-user-identity-role-systems-not-in-iam">
          <Translate contentKey="global.menu.entities.reconSessionUserIdentityRoleSystemsNotInIam" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/recon-session-user-group-systems-not-in-iam">
          <Translate contentKey="global.menu.entities.reconSessionUserGroupSystemsNotInIam" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/recon-session-user-permission-systems-not-in-iam">
          <Translate contentKey="global.menu.entities.reconSessionUserPermissionSystemsNotInIam" />
        </MenuItem>
      </NavDropdown>
    </DropdownItem>
  </NavDropdown>
);
