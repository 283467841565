import dayjs from 'dayjs';

export interface IJobDefinition {
  id?: number;
  name?: string | null;
  internalCode?: string | null;
  publicCode?: string | null;
  startDate?: string | null;
  endDate?: string | null;
}

export const defaultValue: Readonly<IJobDefinition> = {};
