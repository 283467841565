import { ITechnicalOrderAction } from 'app/shared/model/technical-order-action.model';
import { IConnectorCommandAttribute } from 'app/shared/model/connector-command-attribute.model';

export interface ITechnicalOrderActionAttribute {
  id?: number;
  value?: string | null;
  technicalOrderAction?: ITechnicalOrderAction | null;
  attribute?: IConnectorCommandAttribute | null;
}

export const defaultValue: Readonly<ITechnicalOrderActionAttribute> = {};
