import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrder } from 'app/shared/model/order.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { IPermissionDefinition } from 'app/shared/model/permission-definition.model';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { IApplication } from './application.model';

export interface IOrderedSpecificPermission {
  id?: number;
  description?: string | null;
  grantedBy?: PermissionGrantLevel | null;
  executionStatus?: OrderExecutionStatus | null;
  executionResult?: string | null;
  scheduleTime?: string | null;
  executionStartTime?: string | null;
  executionEndTime?: string | null;
  executionEstimatedDuration?: number | null;
  executionActualDuration?: number | null;
  businessValidityStart?: string | null;
  businessValidityEnd?: string | null;
  returnedBusinessValidityStart?: string | null;
  returnedBusinessValidityEnd?: string | null;
  order?: IOrder | null;
  userIdentity?: IUserIdentity | null;
  orderedUserIdentity?: IOrderedUserIdentity | null;
  permissionDefinition?: IPermissionDefinition | null;
  operationType?: string | null;
  application?: IApplication | null;
  dependsOn?: IOrderedSpecificPermission | null;
  originalOrderedSpecificPermission?: IOrderedSpecificPermission | null;
  first?: boolean;
}

export const defaultValue: Readonly<IOrderedSpecificPermission> = {};
