import dayjs from 'dayjs';
import { IApplication } from 'app/shared/model/application.model';

export interface IApplicationResource {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  application?: IApplication | null;
}

export const defaultValue: Readonly<IApplicationResource> = {};
