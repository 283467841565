import { EmailEvents } from 'app/shared/model/enumerations/email-events.model';

export interface IEmailTemplate {
  id?: number;
  name?: string | null;
  emailEvent?: EmailEvents | null;
  subject?: string | null;
  templateContent?: string | null;
  templateJson?: string | null;
}

export const defaultValue: Readonly<IEmailTemplate> = {};
