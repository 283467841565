import { IRiskRule } from 'app/shared/model/risk-rule.model';
import { RiskLevel } from 'app/shared/model/enumerations/risk-level.model';

export interface IRiskCombination {
  id?: number;
  name?: string | null;
  description?: string | null;
  riskLevel?: RiskLevel | null;
  firstRule?: IRiskRule | null;
  secondRule?: IRiskRule | null;
}

export const defaultValue: Readonly<IRiskCombination> = {};
