import dayjs from 'dayjs';
import { IProfileUserIdentity } from 'app/shared/model/profile-user-identity.model';
import { IRoleGroup } from 'app/shared/model/role-group.model';

export interface IProfileUserGroup {
  id?: number;
  descriptionExpression?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  roleGroupExpression?: string | null;
  profileUserIdentity?: IProfileUserIdentity | null;
  roleGroup?: IRoleGroup | null;
}

export const defaultValue: Readonly<IProfileUserGroup> = {};

export const ENTITY_NAME = 'ProfileUserGroup';
