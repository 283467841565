import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './recon-session-user-permission-iam-not-in-systems.reducer';

export const ReconSessionUserPermissionIamNotInSystemsDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const reconSessionUserPermissionIamNotInSystemsEntity = useAppSelector(state => state.reconSessionUserPermissionIamNotInSystems.entity);
  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="reconSessionUserPermissionIamNotInSystemsDetailsHeading">
              <Translate contentKey="iamdentityApp.reconSessionUserPermissionIamNotInSystems.detail.title">
                ReconSessionUserPermissionIamNotInSystems
              </Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserPermissionIamNotInSystemsEntity.id}</dd>
              <dt>
                <span id="processed">
                  <Translate contentKey="iamdentityApp.reconSessionUserPermissionIamNotInSystems.processed">Processed</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserPermissionIamNotInSystemsEntity.processed ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserPermissionIamNotInSystems.processedBy">Processed By</Translate>
              </dt>
              <dd>
                {reconSessionUserPermissionIamNotInSystemsEntity.processedBy
                  ? reconSessionUserPermissionIamNotInSystemsEntity.processedBy.login
                  : ''}
              </dd>
              <dt>
                <span id="comments">
                  <Translate contentKey="iamdentityApp.reconSessionUserPermissionIamNotInSystems.comments">Comments</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserPermissionIamNotInSystemsEntity.comments}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserPermissionIamNotInSystems.iamUserPermission">
                  Iam User Identity
                </Translate>
              </dt>
              <dd>
                {reconSessionUserPermissionIamNotInSystemsEntity.iamUserPermission?.userIdentity
                  ? reconSessionUserPermissionIamNotInSystemsEntity.iamUserPermission.userIdentity?.userName
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserPermissionIamNotInSystems.permissionCode">
                  Permission Definition Code
                </Translate>
              </dt>
              <dd>
                {reconSessionUserPermissionIamNotInSystemsEntity.iamUserPermission?.permissionDefinition
                  ? reconSessionUserPermissionIamNotInSystemsEntity.iamUserPermission.permissionDefinition?.code
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserPermissionIamNotInSystems.iamApplication">Application</Translate>
              </dt>
              <dd>
                {reconSessionUserPermissionIamNotInSystemsEntity.iamUserPermission?.userIdentity?.application
                  ? reconSessionUserPermissionIamNotInSystemsEntity.iamUserPermission.userIdentity.application?.name
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserPermissionIamNotInSystems.importSession">Import Session</Translate>
              </dt>
              <dd>
                {reconSessionUserPermissionIamNotInSystemsEntity.importSession
                  ? reconSessionUserPermissionIamNotInSystemsEntity.importSession.id
                  : ''}
              </dd>
            </dl>
            <Button id="cancel-save" color="info" onClick={() => navigate(-1)} data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button
              tag={Link}
              to={`/recon-session-user-permission-iam-not-in-systems/${reconSessionUserPermissionIamNotInSystemsEntity.id}/edit`}
              replace
              color="primary"
            >
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default ReconSessionUserPermissionIamNotInSystemsDetail;
