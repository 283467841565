import dayjs from 'dayjs';
import { IProfileDefinition } from 'app/shared/model/profile-definition.model';

export interface IStandardPolicyRule {
  id?: number;
  name?: string;
  conditionExpression?: string;
  priority?: number;
  validFrom?: string;
  validTo?: string | null;
  profile?: IProfileDefinition;
}

export const defaultValue: Readonly<IStandardPolicyRule> = {};

export const ENTITY_NAME = 'StandardPolicyRule';
