import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonGroup, Card, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { APP_GLOBAL_DATE_FORMAT, INPUT_ENUM_SEPARATOR } from 'app/config/constants';
import UncontrolledCreatableSelect from 'app/shared/components/react-select/uncontrolled-creatable-select';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { CustomAttributeType } from 'app/shared/model/enumerations/custom-attribute-type.model';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './custom-attribute-definition.reducer';
import ValidatedInput from 'app/shared/components/validated-input';

const DEFAULT_ATTRIBUTE_TYPE = CustomAttributeType.TEXT;

export const CustomAttributeDefinitionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const customAttributeDefinitionEntity = useAppSelector(state => state.customAttributeDefinition.entity);
  const loading = useAppSelector(state => state.customAttributeDefinition.loading);
  const updating = useAppSelector(state => state.customAttributeDefinition.updating);
  const updateSuccess = useAppSelector(state => state.customAttributeDefinition.updateSuccess);
  const customAttributeTypeValues = Object.keys(CustomAttributeType);

  const [selectedObjectTypes, setSelectedObjectTypes] = useState<{ id: number | null; objectType: CustomAttributeApplicableObjectType }[]>(
    []
  );

  const objectTypesGroups = [
    [
      CustomAttributeApplicableObjectType.EMPLOYEE,
      CustomAttributeApplicableObjectType.PERSON,
      CustomAttributeApplicableObjectType.ORGANIZATION_UNIT,
      CustomAttributeApplicableObjectType.JOB_DEFINITION,
    ],
    [CustomAttributeApplicableObjectType.PROJECT, CustomAttributeApplicableObjectType.PROJECT_ASSIGNMENT],
    [
      CustomAttributeApplicableObjectType.APPLICATION,
      CustomAttributeApplicableObjectType.ROLE_DEFINITION,
      CustomAttributeApplicableObjectType.ROLE_GROUP,
      CustomAttributeApplicableObjectType.APPLICATION_RESOURCE,
      CustomAttributeApplicableObjectType.PERMISSION_DEFINITION,
    ],
    [
      CustomAttributeApplicableObjectType.USER_IDENTITY,
      CustomAttributeApplicableObjectType.USER_IDENTITY_ROLE,
      CustomAttributeApplicableObjectType.USER_GROUP,
      CustomAttributeApplicableObjectType.SPECIFIC_PERMISSION,
    ],
    [
      CustomAttributeApplicableObjectType.ORDERED_USER_IDENTITY,
      CustomAttributeApplicableObjectType.ORDERED_ROLE,
      CustomAttributeApplicableObjectType.ORDERED_GROUP,
      CustomAttributeApplicableObjectType.ORDERED_SPECIFIC_PERMISSION,
    ],
    [
      CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY,
      CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY_ROLE,
      CustomAttributeApplicableObjectType.PROFILE_USER_GROUP,
      CustomAttributeApplicableObjectType.PROFILE_SPECIFIC_PERMISSION,
      CustomAttributeApplicableObjectType.PROFILE_DEFINITION,
    ],
  ];

  const arrUserIdentity = [
    CustomAttributeApplicableObjectType.USER_IDENTITY,
    CustomAttributeApplicableObjectType.ORDERED_USER_IDENTITY,
    CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY,
  ];

  const arrAllRoles = [
    CustomAttributeApplicableObjectType.USER_IDENTITY_ROLE,
    CustomAttributeApplicableObjectType.ORDERED_ROLE,
    CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY_ROLE,
  ];

  const arrAllGroups = [
    CustomAttributeApplicableObjectType.USER_GROUP,
    CustomAttributeApplicableObjectType.ORDERED_GROUP,
    CustomAttributeApplicableObjectType.PROFILE_USER_GROUP,
  ];

  const arrAllSpecificPermissions = [
    CustomAttributeApplicableObjectType.SPECIFIC_PERMISSION,
    CustomAttributeApplicableObjectType.ORDERED_SPECIFIC_PERMISSION,
    CustomAttributeApplicableObjectType.PROFILE_SPECIFIC_PERMISSION,
  ];

  const checkArrValues = (value, arr) => arr.some(val => val === value);

  const setSelectedEntity = (objectType: CustomAttributeApplicableObjectType) => {
    let selected = [...selectedObjectTypes];
    const index = selected.findIndex(s => s.objectType == objectType);
    if (index < 0) {
      const foundConnection = customAttributeDefinitionEntity.objectTypes?.find(ot => ot.objectType == objectType);
      if (foundConnection) {
        selected.push({ id: foundConnection.id, objectType: foundConnection.objectType });
      } else {
        if (checkArrValues(objectType, arrUserIdentity)) {
          for (let cad of arrUserIdentity) {
            selected.push({
              id: null,
              objectType: cad,
            });
          }
        } else if (checkArrValues(objectType, arrAllRoles)) {
          for (let cad of arrAllRoles) {
            selected.push({
              id: null,
              objectType: cad,
            });
          }
        } else if (checkArrValues(objectType, arrAllGroups)) {
          for (let cad of arrAllGroups) {
            selected.push({
              id: null,
              objectType: cad,
            });
          }
        } else if (checkArrValues(objectType, arrAllSpecificPermissions)) {
          for (let cad of arrAllSpecificPermissions) {
            selected.push({
              id: null,
              objectType: cad,
            });
          }
        } else {
          selected.push({
            id: null,
            objectType,
          });
        }
      }
    } else {
      const getIndex = type => selected.findIndex(s => s.objectType == type);
      if (checkArrValues(objectType, arrUserIdentity)) {
        for (let cad of arrAllSpecificPermissions) {
          selected.splice(getIndex(cad), 1);
        }
      } else if (checkArrValues(objectType, arrAllRoles)) {
        for (let cad of arrAllRoles) {
          selected.splice(getIndex(cad), 1);
        }
      } else if (checkArrValues(objectType, arrAllGroups)) {
        for (let cad of arrAllGroups) {
          selected.splice(getIndex(cad), 1);
        }
      } else if (checkArrValues(objectType, arrAllSpecificPermissions)) {
        for (let cad of arrAllSpecificPermissions) {
          selected.splice(getIndex(cad), 1);
        }
      } else {
        selected.splice(index, 1);
      }
    }
    setSelectedObjectTypes(selected);
  };

  const handleClose = () => {
    navigate(-1);
  };

  const defaultValues = () =>
    isNew
      ? {
          startDate: moment().format(APP_GLOBAL_DATE_FORMAT),
        }
      : {
          ...customAttributeDefinitionEntity,
          selectableOptions: customAttributeDefinitionEntity?.selectableOptions
            ?.split(INPUT_ENUM_SEPARATOR)
            .map(op => ({ label: op, value: op })),
        };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    formState: { errors, touchedFields },
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  useEffect(() => {
    setSelectedObjectTypes(
      customAttributeDefinitionEntity?.objectTypes?.map(objectType => ({ id: objectType.id, objectType: objectType.objectType })) ?? []
    );

    formReset(defaultValues());
  }, [customAttributeDefinitionEntity]);

  const { ref: customAttributeTypeRef, ...customAttributeTypeProps } = register('customAttributeType');
  const customAttributeTypeWatch = watch('customAttributeType');

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.customAttributeType != CustomAttributeType.ENUMERATION) {
      values.selectableOptions = null;
    }

    const entity = {
      ...customAttributeDefinitionEntity,
      ...values,
      objectTypes: selectedObjectTypes.map(ot => ({ id: ot.id, objectType: ot.objectType })),
      selectableOptions: values?.selectableOptions?.map(op => op.value).join(INPUT_ENUM_SEPARATOR),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.customAttributeDefinition.home.createOrEditLabel" data-cy="CustomAttributeDefinitionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.customAttributeDefinition.home.createOrEditLabel">
              Create or edit a CustomAttributeDefinition
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="custom-attribute-definition-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedInput
                control={control}
                label={translate('iamdentityApp.customAttributeDefinition.displayName')}
                name="displayName"
                data-cy="displayName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.customAttributeDefinition.description')}
                id="custom-attribute-definition-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.customAttributeType" />
                </Label>
                <Input
                  id="custom-attribute-definition-customAttributeType"
                  type="select"
                  valid={touchedFields.customAttributeType && !errors.customAttributeType}
                  invalid={!!errors.customAttributeType}
                  {...customAttributeTypeProps}
                  innerRef={customAttributeTypeRef}
                >
                  {customAttributeTypeValues.map(customAttributeType => (
                    <option value={customAttributeType} key={customAttributeType}>
                      {translate('iamdentityApp.CustomAttributeType.' + customAttributeType)}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {customAttributeTypeWatch == CustomAttributeType.ENUMERATION ? (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.customAttributeDefinition.selectableOptions" />
                  </Label>
                  <UncontrolledCreatableSelect control={control} name="selectableOptions" />
                </FormGroup>
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.customAttributeDefinition.displayOrder')}
                id="custom-attribute-definition-displayOrder"
                name="displayOrder"
                data-cy="displayOrder"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.customAttributeDefinition.visibleInList')}
                id="custom-attribute-definition-visibleInList"
                name="visibleInList"
                data-cy="visibleInList"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                max={watchEndDate?.toString()}
                label={translate('iamdentityApp.customAttributeDefinition.startDate')}
                id="custom-attribute-definition-startDate"
                name="startDate"
                data-cy="startDate"
                type="date"
              />
              <ValidatedField
                register={register}
                min={watchStartDate?.toString()}
                label={translate('iamdentityApp.customAttributeDefinition.endDate')}
                id="custom-attribute-definition-endDate"
                name="endDate"
                data-cy="endDate"
                type="date"
              />
              <label className="form-label">
                <Translate contentKey="iamdentityApp.customAttributeDefinition.objectTypes" />
              </label>
              {objectTypesGroups.map((group, index) => (
                <Row className="mb-3" key={`object-type-group-${index}`}>
                  <ButtonGroup className="groupCollapse">
                    {group.map((objectType: CustomAttributeApplicableObjectType) => (
                      <Button
                        key={objectType}
                        color="secondary"
                        outline
                        block
                        onClick={() => setSelectedEntity(objectType)}
                        active={!!selectedObjectTypes.find(ot => ot.objectType == objectType)}
                      >
                        <Translate contentKey={`iamdentityApp.CustomAttributeApplicableObjectType.${objectType}`} />
                      </Button>
                    ))}
                  </ButtonGroup>
                </Row>
              ))}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default CustomAttributeDefinitionUpdate;
