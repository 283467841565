import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './user-group.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const UserGroupDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.USER_GROUP));
  }, []);

  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const userGroupEntity = useAppSelector(state => state.userGroup.entity);

  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="userGroupDetailsHeading">
              <Translate contentKey="iamdentityApp.userGroup.detail.title">UserGroup</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{userGroupEntity.id}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.userGroup.employee">Employee</Translate>
              </dt>
              <dd>
                {userGroupEntity.userIdentity?.employee
                  ? userGroupEntity.userIdentity.employee.fullName
                    ? userGroupEntity.userIdentity.employee.code + ' - ' + userGroupEntity.userIdentity.employee.fullName
                    : userGroupEntity.userIdentity.employee.code
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.userGroup.application">Application</Translate>
              </dt>
              <dd>{userGroupEntity.userIdentity?.application ? userGroupEntity.userIdentity.application.name : ''}</dd>
              <dt>
                <span id="status">
                  <Translate contentKey="iamdentityApp.userGroup.status">Status</Translate>
                </span>
              </dt>
              <dd>{userGroupEntity.status}</dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.userGroup.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {userGroupEntity.startDate ? (
                  <TextFormat value={userGroupEntity.startDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.userGroup.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {userGroupEntity.endDate ? (
                  <TextFormat value={userGroupEntity.endDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="grantedBy">
                  <Translate contentKey="iamdentityApp.userGroup.grantedBy">Granted By</Translate>
                </span>
              </dt>
              <dd>{translate('iamdentityApp.PermissionGrantLevel.' + userGroupEntity.grantedBy)}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.userGroup.userIdentity">User Identity</Translate>
              </dt>
              <dd>{userGroupEntity.userIdentity ? userGroupEntity.userIdentity.userName : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.userGroup.roleGroup">Role Group</Translate>
              </dt>
              <dd>{userGroupEntity.roleGroup ? userGroupEntity.roleGroup.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.userGroup.lastModifiedByOrder">Last Modified By Order</Translate>
              </dt>
              <dd>{userGroupEntity.lastModifiedByOrder ? userGroupEntity.lastModifiedByOrder.id : ''}</dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, userGroupEntity)}</dd>
                </React.Fragment>
              ))}
            </dl>
            <div className="btn-group flex-btn-group-container">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
              {/* <Button tag={Link} to={`/user-group/${userGroupEntity.id}/edit`} color="primary">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button> */}
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.USER_GROUP} />
    </React.Fragment>
  );
};

export default UserGroupDetail;
