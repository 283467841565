import dayjs from 'dayjs';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { IRoleDefinition } from 'app/shared/model/role-definition.model';
import { IPermissionDefinition } from 'app/shared/model/permission-definition.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrder } from 'app/shared/model/order.model';
import { UserStatus } from 'app/shared/model/enumerations/user-status.model';

export interface IUserIdentityRole {
  id?: number;
  description?: string | null;
  status?: UserStatus | null;
  startDate?: string | null;
  endDate?: string | null;
  grantedBy?: PermissionGrantLevel | null;
  userIdentity?: IUserIdentity | null;
  roleDefinition?: IRoleDefinition | null;
  permissionDefinition?: IPermissionDefinition | null;
  lastModifiedByOrder?: IOrder | null;
  userIdentityName?: string | null;
}

export const defaultValue: Readonly<IUserIdentityRole> = {};
