import dayjs from 'dayjs';
import { IOrder } from 'app/shared/model/order.model';
import { IApplication } from 'app/shared/model/application.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { OperationType } from 'app/shared/model/enumerations/operation-type.model';
import { IOrderedRole } from './ordered-role.model';
import { IOrderedGroup } from './ordered-group.model';
import { IOrderedSpecificPermission } from './ordered-specific-permission.model';
import { IUserIdentity } from 'app/shared/model/user-identity.model';

export interface IOrderedUserIdentity {
  id?: number;
  userName?: string | null;
  alternativeUserName?: string | null;
  associatedEmailAddress?: string | null;
  displayName?: string | null;
  initialPassword?: string | null;
  mustChangePasswordOnFirstLogin?: boolean | null;
  dateNextChangePassword?: string | null;
  returnedUserName?: string | null;
  returnedAlternativeUserName?: string | null;
  returnedAssociatedEmailAddress?: string | null;
  returnedDisplayName?: string | null;
  returnedInitialPassword?: string | null;
  returnedMustChangePasswordOnFirstLogin?: boolean | null;
  returnedDateNextChangePassword?: string | null;
  grantedBy?: PermissionGrantLevel | null;
  executionStatus?: OrderExecutionStatus | null;
  executionResult?: string | null;
  scheduleTime?: string | null;
  executionStartTime?: string | null;
  executionEndTime?: string | null;
  executionEstimatedDuration?: number | null;
  executionActualDuration?: number | null;
  businessValidityStart?: string | null;
  businessValidityEnd?: string | null;
  returnedBusinessValidityStart?: string | null;
  returnedBusinessValidityEnd?: string | null;
  retrieveUserNameFromDependentOrderedUserIdentity?: boolean | null;
  operationType?: OperationType | null;
  userSuspended?: boolean | null;
  returnedUserSuspended?: boolean | null;
  order?: IOrder | null;
  application?: IApplication | null;
  employee?: IEmployee | null;
  dependsOn?: IOrderedUserIdentity | null;
  orderedRoles?: IOrderedRole[] | null;
  orderedGroups?: IOrderedGroup[] | null;
  orderedSpecificPermissions?: IOrderedSpecificPermission[] | null;
  first?: boolean;
  dependsOnExisting?: IUserIdentity | null;
  relatedUserIdentity?: IUserIdentity | null;
  originalOrderedUserIdentity?: IOrderedUserIdentity | null;
}

export const defaultValue: Readonly<IOrderedUserIdentity> = {
  mustChangePasswordOnFirstLogin: false,
  returnedMustChangePasswordOnFirstLogin: false,
  retrieveUserNameFromDependentOrderedUserIdentity: false,
};
