import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BPMType } from 'app/shared/model/enumerations/bpm-type.model';
import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import useModal from 'app/shared/components/modal/modal.hook';
import ControlledPaginatedReactSelect from 'app/shared/components/react-select/controlled-paginated-react-select';
import { TaskStatus } from 'app/shared/model/enumerations/task-status.model';
import {
  CompleteTask,
  FormHeaderProperties,
  ITask,
  ITaskBusinessForm,
  IToaTask,
  TaskNames,
  TaskTypes,
  VariableNames,
  VariableValueTypes,
} from 'app/shared/model/task.model';
import { IUser } from 'app/shared/model/user.model';
import { apiUrl as usersApi } from 'app/shared/reducers/user-management';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label } from 'reactstrap';
import '../style.scss';
import { cancelProcess, claimTask, completeTask, delegateTask, getEntity, refreshTaskList } from '../tasks.reducer';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import axios from 'axios';

interface IActionsCardProps {
  task: IToaTask;
}

export const getVarNameByTaskName = (taskName: TaskNames, type: 'approve' | 'reject') => {
  switch (taskName) {
    case TaskNames.Aproba_Level_1:
      return { name: VariableNames.lvl1Approved, value: type == 'approve' ? true : false };
    case TaskNames.Aproba_Level_2:
      return { name: VariableNames.lvl2Approved, value: type == 'approve' ? true : false };
    case TaskNames.Aproba_Level_3:
      return { name: VariableNames.lvl3Approved, value: type == 'approve' ? true : false };
    case TaskNames.Revizuieste_Cererea:
      return { name: VariableNames.canceled, value: type == 'approve' ? false : true };
  }
};

const ActionsCard = ({ task }: IActionsCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const modal = useModal();

  const account = useAppSelector(state => state.authentication.account);
  const order = useAppSelector(state => state.task.entity);
  const [assignee, setAssignee] = useState({ id: account.id, login: account.login });
  const { updating, updateSuccess } = useAppSelector(state => state.task);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(refreshTaskList());
      if (task.status == TaskStatus.CREATED) {
        dispatch(getEntity(task.id));
      } else {
        navigate(-1);
      }
    }
  }, [updateSuccess]);

  const approve = () => {
    const { name, value } = getVarNameByTaskName(task.name, 'approve');
    const payload: CompleteTask = {
      variables: {
        name: [name],
        value: [value],
        valueType: [VariableValueTypes.boolean],
      },
    };

    dispatch(completeTask({ id: task.id, payload }));
  };

  const reject = () => {
    const { name, value } = getVarNameByTaskName(task.name, 'reject');
    const payload: CompleteTask = {
      variables: {
        name: [name],
        value: [value],
        valueType: [VariableValueTypes.boolean],
      },
    };

    dispatch(completeTask({ id: task.id, payload }));
  };

  const retry = () => {
    const payload: CompleteTask = {
      variables: {
        name: [VariableNames.retry],
        value: [true],
        valueType: [VariableValueTypes.boolean],
      },
    };

    dispatch(completeTask({ id: task.id, payload }));
  };

  const sendtoAdmin = () => {
    const payload: CompleteTask = {
      variables: {
        name: [VariableNames.escalate],
        value: [true],
        valueType: [VariableValueTypes.boolean],
      },
    };

    dispatch(completeTask({ id: task.id, payload }));
  };

  const claim = () => {
    dispatch(claimTask(task.id));
  };

  const delegate = (taskId: string, login: string) => {
    dispatch(delegateTask({ taskId, login }));
  };

  const onAssigneeChange = (value: IUser) => {
    modal.show({
      title: translate('iamdentityApp.tasks.changeAssignee.title'),
      body: translate('iamdentityApp.tasks.changeAssignee.body', { assignee: value.login }),
      callback: delegate.bind(null, task.id, value.login),
    });
  };

  const handleCancelToa = () => {
    const payload = {
      businessForm: null,
      variables: {
        name: [VariableNames.retry, VariableNames.is_cancelled],
        value: [false, true],
        valueType: [VariableValueTypes.boolean, VariableValueTypes.boolean],
      },
    };
    dispatch(completeTask({ id: task.id, payload }));
  };

  const startProcessQueryCanCancel = async () => {
    return await axios.get(`api/user-process/can_cancel?toaId=${task.toa.id}`);
  };

  const { isSuccess: successCanCancel, data: canCancel } = useQuery('canCancel', startProcessQueryCanCancel, {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const showRetryButton =
    task?.businessForm?.header?.properties.find(prop => prop.name.includes(FormHeaderProperties.executionStage)).value !== 'SAVE_TO_SPR';
  const renderActions = () => {
    return (
      <React.Fragment>
        {task.processDefinitionKey == BPMType.Process_Order_Approval ? (
          <>
            <FormGroup>
              <Label>
                <Translate contentKey="iamdentityApp.tasks.changeAssignee.title" />
              </Label>
              <ControlledPaginatedReactSelect
                value={assignee}
                onChange={onAssigneeChange}
                isClearable={false}
                url={`${usersApi}/authority/${AUTHORITIES.CAMUNDA_USER}`}
                queryProps={{
                  name: 'login',
                  type: 'string',
                }}
                hideSelectedOptions={true}
              />
            </FormGroup>
            <div className="btn-group flex-btn-group-container">
              <React.Fragment>
                <Button disabled={updating} onClick={reject} color="danger">
                  <FontAwesomeIcon icon="thumbs-down" />{' '}
                  <span>
                    <Translate contentKey="iamdentityApp.tasks.deny">Deny</Translate>
                  </span>
                </Button>
                <Button disabled={updating} onClick={approve} color="success">
                  <FontAwesomeIcon icon="thumbs-up" />{' '}
                  <span>
                    <Translate contentKey="iamdentityApp.tasks.approve">Approve</Translate>
                  </span>
                </Button>
              </React.Fragment>
            </div>
          </>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {task.processDefinitionKey == BPMType.Process_Order_Approval ? (
        <Card color="secondary">
          <CardHeader>
            <Translate contentKey="iamdentityApp.tasks.actions">Actions</Translate>
          </CardHeader>
          <CardBody className="flex-div justify-self-center flex-column">
            {task.status == TaskStatus.ASSIGNED ? (
              renderActions()
            ) : task.status == TaskStatus.CREATED ? (
              <div className="btn-group flex-btn-group-container">
                <Button disabled={updating} onClick={claim} color="danger">
                  <FontAwesomeIcon icon="circle-exclamation" />{' '}
                  <span>
                    <Translate contentKey="iamdentityApp.tasks.claim">Claim</Translate>
                  </span>
                </Button>
              </div>
            ) : null}
          </CardBody>
        </Card>
      ) : (
        <React.Fragment>
          <Card color="secondary">
            <CardHeader>
              <Translate contentKey="iamdentityApp.tasks.actions">Actions</Translate>
            </CardHeader>
            {task.status == TaskStatus.CREATED ? (
              <CardBody className="flex-div justify-self-center flex-column">
                <div className="btn-group flex-btn-group-container">
                  <Button disabled={updating} onClick={claim} color="danger">
                    <FontAwesomeIcon icon="circle-exclamation" />{' '}
                    <span>
                      <Translate contentKey="iamdentityApp.tasks.claim">Claim</Translate>
                    </span>
                  </Button>
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.tasks.changeAssignee.title" />
                  </Label>
                  <ControlledPaginatedReactSelect
                    value={assignee}
                    onChange={onAssigneeChange}
                    isClearable={false}
                    url={`${usersApi}/authority/${AUTHORITIES.CAMUNDA_USER}`}
                    queryProps={{
                      name: 'login',
                      type: 'string',
                    }}
                    hideSelectedOptions={true}
                  />
                </FormGroup>

                <div className="btn-group flex-btn-group-container flex-end">
                  {showRetryButton && (
                    <Button disabled={updating} onClick={retry} color="info">
                      <FontAwesomeIcon icon="sync" /> <span>{translate('iamdentityApp.tasks.retry')}</span>
                    </Button>
                  )}

                  {order.name === TaskTypes.Resolve_Toa_Timeout_Admin && successCanCancel && canCancel?.data?.value && (
                    <Button onClick={handleCancelToa} color="primary">
                      <FontAwesomeIcon icon="ban" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                      </span>
                    </Button>
                  )}

                  {(order.name === TaskTypes.Resolve_Toa || order.name === TaskTypes.Resolve_Toa_Timeout) && (
                    <React.Fragment>
                      <Button disabled={updating} onClick={sendtoAdmin} color="danger">
                        <FontAwesomeIcon icon={faPaperPlane} /> <span>{translate('iamdentityApp.tasks.sendToAdmin')}</span>
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </CardBody>
            )}
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ActionsCard;
