import dayjs from 'dayjs';
import { IOrganizationUnitType } from 'app/shared/model/organization-unit-type.model';
import { ILocation } from 'app/shared/model/location.model';
import { IEmployee } from 'app/shared/model/employee.model';

export interface IOrganizationUnit {
  id?: number;
  name?: string | null;
  shortName?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  organizationUnitType?: IOrganizationUnitType | null;
  location?: ILocation | null;
  parentOrganization?: IOrganizationUnit | null;
  manager?: IEmployee | null;
  code?: string | null;
}

export const defaultValue: Readonly<IOrganizationUnit> = {};
