import dayjs from 'dayjs';
import { IConnectorType } from 'app/shared/model/connector-type.model';

export interface IConnectorCommand {
  id?: number;
  code?: string;
  description?: string | null;
  validFrom?: string;
  validTo?: string | null;
  connectorType?: IConnectorType | null;
}

export const defaultValue: Readonly<IConnectorCommand> = {};
