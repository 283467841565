import { IOrder } from 'app/shared/model/order.model';
import { IUser } from './user.model';

export interface IOrderComments {
  id?: number;
  description?: string | null;
  order?: IOrder | null;
  user?: IUser | null;
  username?: string | null;
}

export const defaultValue: Readonly<IOrderComments> = {};
