import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { IUser } from 'app/shared/model/user.model';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IReconSessionUserIdentityIamNotInSystems {
  id?: number;
  processed?: boolean | null;
  comments?: string | null;
  iamUserIdentity?: IUserIdentity | null;
  processedBy?: IUser | null;
  importSession?: IImportSession | null;
}

export const defaultValue: Readonly<IReconSessionUserIdentityIamNotInSystems> = {
  processed: false,
};
