import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './profile-user-group.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const ProfileUserGroupDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { profileUserGroupId: id } = useParams<'profileUserGroupId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_USER_GROUP));
  }, []);

  const profileUserGroupEntity = useAppSelector(state => state.profileUserGroup.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card pad-1">
          <Col md="8">
            <h2 data-cy="profileUserGroupDetailsHeading">
              <Translate contentKey="iamdentityApp.profileUserGroup.detail.title">ProfileUserGroup</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{profileUserGroupEntity.id}</dd>
              <dt>
                <span id="descriptionExpression">
                  <Translate contentKey="iamdentityApp.profileUserGroup.descriptionExpression">Description Expression</Translate>
                </span>
              </dt>
              <dd>
                {profileUserGroupEntity.descriptionExpression ? (
                  <CompactExpressionBuilder tree={profileUserGroupEntity.descriptionExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.profileUserGroup.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {profileUserGroupEntity.startDate ? (
                  <TextFormat value={profileUserGroupEntity.startDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.profileUserGroup.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {profileUserGroupEntity.endDate ? (
                  <TextFormat value={profileUserGroupEntity.endDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="roleGroupExpression">
                  <Translate contentKey="iamdentityApp.profileUserGroup.roleGroupExpression">Role Group Expression</Translate>
                </span>
              </dt>
              <dd>
                {profileUserGroupEntity.roleGroupExpression ? (
                  <CompactExpressionBuilder tree={profileUserGroupEntity.roleGroupExpression} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.profileUserGroup.profileUserIdentity">Profile User Identity</Translate>
              </dt>
              <dd>{profileUserGroupEntity.profileUserIdentity ? profileUserGroupEntity.profileUserIdentity.id : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.profileUserGroup.roleGroup">Role Group</Translate>
              </dt>
              <dd>{profileUserGroupEntity.roleGroup ? profileUserGroupEntity.roleGroup.name : ''}</dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, profileUserGroupEntity)}</dd>
                </React.Fragment>
              ))}
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.PROFILE_USER_GROUP} />
    </React.Fragment>
  );
};

export default ProfileUserGroupDetail;
