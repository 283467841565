import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, updateEntity, createEntity, reset } from './import-session.reducer';

export const ImportSessionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const importSessionEntity = useAppSelector(state => state.importSession.entity);
  const loading = useAppSelector(state => state.importSession.loading);
  const updating = useAppSelector(state => state.importSession.updating);
  const updateSuccess = useAppSelector(state => state.importSession.updateSuccess);

  const handleClose = () => {
    navigate('/import-session' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...importSessionEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...importSessionEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.importSession.home.createOrEditLabel" data-cy="ImportSessionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.importSession.home.createOrEditLabel">Create or edit a ImportSession</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="import-session-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('iamdentityApp.importSession.sourceDataIdentifier')}
                id="import-session-sourceDataIdentifier"
                name="sourceDataIdentifier"
                data-cy="sourceDataIdentifier"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.importSession.recordsInFile')}
                id="import-session-recordsInFile"
                name="recordsInFile"
                data-cy="recordsInFile"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.importSession.recordsWithError')}
                id="import-session-recordsWithError"
                name="recordsWithError"
                data-cy="recordsWithError"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.importSession.dateOfDataExport')}
                id="import-session-dateOfDataExport"
                name="dateOfDataExport"
                data-cy="dateOfDataExport"
                type="date"
              />
              <ValidatedField
                label={translate('iamdentityApp.importSession.reconciliationPerformed')}
                id="import-session-reconciliationPerformed"
                name="reconciliationPerformed"
                data-cy="reconciliationPerformed"
                check
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/import-session" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ImportSessionUpdate;
