import { IConnectorCommand } from 'app/shared/model/connector-command.model';
import { IApplication } from 'app/shared/model/application.model';
import { OperationType } from 'app/shared/model/enumerations/operation-type.model';

export interface ITechnicalOrderActionCreate {
  id?: number;
  createForUserIdentity?: boolean | null;
  createForRole?: boolean | null;
  createForGroup?: boolean | null;
  createForSpecificPermission?: boolean | null;
  operationType?: OperationType | null;
  priority?: number | null;
  connectorCommand?: IConnectorCommand | null;
  application?: IApplication | null;
}

export const defaultValue: Readonly<ITechnicalOrderActionCreate> = {
  createForUserIdentity: false,
  createForRole: false,
  createForGroup: false,
  createForSpecificPermission: false,
};
