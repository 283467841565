import dayjs from 'dayjs';

export interface ILocation {
  id?: number;
  code?: string | null;
  name?: string | null;
  startDate?: string | null;
  endDate?: string | null;
}

export const defaultValue: Readonly<ILocation> = {};
