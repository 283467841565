import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isNumber, Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';

import { AUTHORITIES } from 'app/config/constants';
import { getEntities as getJobDefinitions } from 'app/entities/job-definition/job-definition.reducer';
import { getEntities as getOrderTypes } from 'app/entities/order-type/order-type.reducer';
import { getEntities as getOrganizationUnits } from 'app/entities/organization-unit/organization-unit.reducer';
import { OrderApprovalAssignmentMethod } from 'app/shared/model/enumerations/order-approval-assignment-method.model';
import { createEntity, getEntity, reset, updateEntity } from './order-approval-levels.reducer';

import { apiUrl as jobDefinitionsUrl } from 'app/entities/job-definition/job-definition.reducer';
import { apiUrl as orderTypeUrl } from 'app/entities/order-type/order-type.reducer';
import { apiUrl as organizationUnitUrl } from 'app/entities/organization-unit/organization-unit.reducer';
import { apiUrl as usersUrl } from 'app/shared/reducers/user-management';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import ExpressionBuilder from 'app/shared/components/expression-builder/expression-builder';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import UncontrolledReactSelect from 'app/shared/components/react-select/uncontrolled-react-select';
import { RiskLevel } from 'app/shared/model/enumerations/risk-level.model';
import { IOrderApprovalLevels, ENTITY_NAME } from 'app/shared/model/order-approval-levels.model';
import { convertReactSelectValuesToEntityIds } from 'app/shared/util/entity-utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { ValueType } from 'app/shared/components/expression-builder/types';

export const OrderApprovalLevelsUpdate = () => {
  const dispatch = useAppDispatch();
  const authorities = AUTHORITIES;

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const orderApprovalLevelsEntity = useAppSelector(state => state.orderApprovalLevels.entity);
  const loading = useAppSelector(state => state.orderApprovalLevels.loading);
  const updating = useAppSelector(state => state.orderApprovalLevels.updating);
  const updateSuccess = useAppSelector(state => state.orderApprovalLevels.updateSuccess);
  const orderApprovalAssignmentMethodValues: string[] = Object.values(OrderApprovalAssignmentMethod);
  const checkValidity = validTo => (!validTo || moment(validTo) > moment() ? false : true);

  const handleClose = () => {
    navigate(-1);
  };
  const riskLevelValues = Object.values(RiskLevel);
  const [selectionConditionExpression, setSelectionConditionExpression] = useState(null);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getOrderTypes({}));
    dispatch(getOrganizationUnits({}));
    dispatch(getJobDefinitions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setSelectionConditionExpression(orderApprovalLevelsEntity?.selectionConditionExpression);
    formReset(defaultValues());
  }, [orderApprovalLevelsEntity]);

  const extractLevelValues = (level: number, values: any) => {
    return {
      [`level${level}AssignmentMethod`]:
        values[`level${level}AssignmentMethod`] == '' ? undefined : values[`level${level}AssignmentMethod`],
      [`level${level}NominalUsers`]:
        values[`level${level}AssignmentMethod`] == OrderApprovalAssignmentMethod.NOMINAL_USERS
          ? values[`level${level}NomUser`]?.map(user => user['login']).join(',')
          : undefined,
      [`level${level}OrganizationUnit`]:
        values[`level${level}AssignmentMethod`] == OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION
          ? values[`level${level}OrganizationUnit`]
          : undefined,
      [`level${level}IAMRole`]:
        values[`level${level}AssignmentMethod`] == OrderApprovalAssignmentMethod.IAM_ROLE
          ? { name: values[`level${level}IAMRole`] }
          : undefined,
      [`level${level}Job`]:
        values[`level${level}AssignmentMethod`] == OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION
          ? values[`level${level}Job`]
          : undefined,
    };
  };

  //populate and save date
  const saveEntity = values => {
    const entity: IOrderApprovalLevels = {
      ...orderApprovalLevelsEntity,
      ...convertReactSelectValuesToEntityIds(values),

      selectionConditionExpression: selectionConditionExpression,
      ...extractLevelValues(1, values),
      ...extractLevelValues(2, values),
      ...extractLevelValues(3, values),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  //default values
  const defaultValues = () => {
    const result = isNew
      ? {
          validFrom: moment().format(APP_GLOBAL_DATE_FORMAT),
        }
      : {
          ...orderApprovalLevelsEntity,
          level1NomUser: orderApprovalLevelsEntity?.level1NominalUsersList
            ? orderApprovalLevelsEntity?.level1NominalUsersList
            : orderApprovalLevelsEntity?.level1NominalUsers
            ? orderApprovalLevelsEntity?.level1NominalUsers.split(',').map(userId => ({
                id: userId,
                employee: {
                  fullName: '',
                },
              }))
            : [],
          level2NomUser: orderApprovalLevelsEntity?.level2NominalUsersList
            ? orderApprovalLevelsEntity?.level2NominalUsersList
            : orderApprovalLevelsEntity?.level2NominalUsers
            ? orderApprovalLevelsEntity?.level2NominalUsers.split(',').map(userId => ({
                id: userId,
                employee: {
                  fullName: '',
                },
              }))
            : [],
          level3NomUser: orderApprovalLevelsEntity?.level3NominalUsersList
            ? orderApprovalLevelsEntity?.level3NominalUsersList
            : orderApprovalLevelsEntity?.level3NominalUsers
            ? orderApprovalLevelsEntity?.level3NominalUsers.split(',').map(userId => ({
                id: userId,
                employee: {
                  fullName: '',
                },
              }))
            : [],
          level1IAMRole: orderApprovalLevelsEntity?.level1IAMRole?.name,
          level2IAMRole: orderApprovalLevelsEntity?.level2IAMRole?.name,
          level3IAMRole: orderApprovalLevelsEntity?.level3IAMRole?.name,
          riskLevel: orderApprovalLevelsEntity?.riskLevel ? orderApprovalLevelsEntity?.riskLevel : 'LOW',
        };
    return result;
  };

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    setValue,
    control,
    reset: formReset,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  //ref for input labels to control inputs props
  //assignment ref
  const { ref: level1AssignmentMethodRef, ...level1AssignmentMethodProps } = register('level1AssignmentMethod', { required: true });
  const { ref: level2AssignmentMethodRef, ...level2AssignmentMethodProps } = register('level2AssignmentMethod');
  const { ref: level3AssignmentMethodRef, ...level3AssignmentMethodProps } = register('level3AssignmentMethod');

  //nominal users ref
  const { ref: level1NomUserRef, ...level1NomUserProps } = register('level1NomUser');
  const { ref: level2NomUserRef, ...level2NomUserProps } = register('level2NomUser');
  const { ref: level3NomUserRef, ...level3NomUserProps } = register('level3NomUser');

  //employee job ref
  const { ref: level1JobRef, ...level1JobProps } = register('level1Job');
  const { ref: level2JobRef, ...level2JobProps } = register('level2Job');
  const { ref: level3JobRef, ...level3JobProps } = register('level3Job');

  //organization level ref
  const { ref: level1OrganizationUnitRef, ...level1OrganizationUnitProps } = register('level1OrganizationUnit');
  const { ref: level2OrganizationUnitRef, ...level2OrganizationUnitProps } = register('level2OrganizationUnit');
  const { ref: level3OrganizationUnitRef, ...level3OrganizationUnitProps } = register('level3OrganizationUnit');

  //iam role level ref
  const { ref: level1IAMRoleRef, ...level1IAMRoleProps } = register('level1IAMRole');
  const { ref: level2IAMRoleRef, ...level2IAMRoleProps } = register('level2IAMRole');
  const { ref: level3IAMRoleRef, ...level3IAMRoleProps } = register('level3IAMRole');

  const { ref: nameRef, ...nameProps } = register('name', { required: true });
  const { ref: priorityRef, ...priorityProps } = register('priority', { required: true, validate: v => isNumber(v) });
  const { ref: validFromRef, ...validFromProps } = register('validFrom', { required: true });
  const { ref: validToRef, ...validToProps } = register('validTo');

  //form hook to manipulate behavior
  const lvl1Assing = watch('level1AssignmentMethod');
  const lvl2Assing = watch('level2AssignmentMethod');
  const lvl3Assing = watch('level3AssignmentMethod');

  //group states 1
  const level1NomUsers = watch('level1NomUser');
  const level1Job = watch('level1Job');
  const level1OrganizationUnit = watch('level1OrganizationUnit');
  const level1IamRole = watch('level1IAMRole');

  //group states 2
  const level2NomUsers = watch('level2NomUser');
  const level2Job = watch('level2Job');
  const level2OrganizationUnit = watch('level2OrganizationUnit');
  const level2IamRole = watch('level2IAMRole');

  const verifyIfValueFromGroupOneIsSelected =
    level1NomUsers || level1Job || level1OrganizationUnit || level1IamRole || lvl1Assing === OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR
      ? false
      : true;
  const verifyIfValueFromGroupTwoIsSelected =
    level2NomUsers || level2Job || level2OrganizationUnit || level2IamRole || lvl2Assing === OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR
      ? false
      : true;

  //state value for inputs
  const name = watch('name');
  const priority = watch('priority');
  const validFrom = watch('validFrom');
  const validTo = watch('validTo');

  //reset field values for specific group
  const resetGroupFileds = (group: number) => {
    resetField(`level${group}NomUser`);
    register(`level${group}NomUser`, { required: false });
    resetField(`level${group}Job`);
    register(`level${group}Job`, { required: false });
    resetField(`level${group}OrganizationUnit`);
    register(`level${group}OrganizationUnit`, { required: false });
    resetField(`level${group}IAMRole`);
    register(`level${group}IAMRole`, { required: false });
  };

  //force and set default value for level 1 assignment method to nominal user
  useEffect(() => {
    setValue('level1AssignmentMethod', OrderApprovalAssignmentMethod.NOMINAL_USERS);
  }, []);

  //reset group fields properties everytime lvl1assigment value changes
  useEffect(() => {
    resetGroupFileds(1);
    if (lvl1Assing === OrderApprovalAssignmentMethod.NOMINAL_USERS) {
      register('level1NomUser', { required: true });
    }
    if (lvl1Assing === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION) {
      register('level1OrganizationUnit', { required: true });
      register('level1Job', { required: true });
    }
    if (lvl1Assing === OrderApprovalAssignmentMethod.IAM_ROLE) {
      register('level1IAMRole', { required: true });
    }

    if (lvl1Assing === OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR) {
      resetGroupFileds(2);
      resetField('level2AssignmentMethod');
      resetGroupFileds(3);
      resetField('level3AssignmentMethod');
    }
  }, [lvl1Assing]);

  //reset group fields properties everytime lvl2assigment value changes
  useEffect(() => {
    resetGroupFileds(2);

    if (!lvl2Assing) {
      resetGroupFileds(3);
    }

    if (lvl2Assing === OrderApprovalAssignmentMethod.NOMINAL_USERS) {
      register('level2NomUser', { required: true });
    }

    if (lvl2Assing === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION) {
      register('level2OrganizationUnit', { required: true });
      register('level2Job', { required: true });
    }
    if (lvl2Assing === OrderApprovalAssignmentMethod.IAM_ROLE) {
      register('level2IAMRole', { required: true });
    }

    if (lvl2Assing === OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR) {
      resetGroupFileds(3);
      resetField('level3AssignmentMethod');
    }
  }, [lvl2Assing]);

  useEffect(() => {
    resetGroupFileds(3);
    if (!lvl3Assing) {
      register(`level3AssignmentMethod`, { required: false });
    }
    if (lvl3Assing === OrderApprovalAssignmentMethod.NOMINAL_USERS) {
      register('level3NomUser', { required: true });
    }
    if (lvl3Assing === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION) {
      register('level3OrganizationUnit', { required: true });
      register('level3Job', { required: true });
    }
    if (lvl3Assing === OrderApprovalAssignmentMethod.IAM_ROLE) {
      register('level3IAMRole', { required: true });
    }
  }, [lvl3Assing]);

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.orderApprovalLevels.home.createOrEditLabel" data-cy="OrderApprovalLevelsCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.orderApprovalLevels.home.createOrEditLabel">
              Create or edit a OrderApprovalLevels
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="order-approval-levels-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.name" />
                </Label>
                <Input
                  valid={touchedFields.name && name && !errors.name}
                  invalid={!!errors.name}
                  type="text"
                  {...nameProps}
                  innerRef={nameRef}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.priority" />
                </Label>
                <Input
                  valid={touchedFields.priority && priority && !errors.priority}
                  invalid={!!errors.priority}
                  type="text"
                  {...priorityProps}
                  innerRef={priorityRef}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.selectionConditionExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={selectionConditionExpression}
                  restrictedValueType={ValueType.BOOLEAN}
                  onChange={expression => setSelectionConditionExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.orderType" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="orderType"
                  url={orderTypeUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  validate={{ required: true }}
                />
              </FormGroup>
              {/* Assign Methon 1 */}
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level1AssignmentMethod" />
                </Label>
                <Input
                  valid={touchedFields.level1AssignmentMethod && !errors.level1AssignmentMethod} //set conditions
                  invalid={!!errors.level1AssignmentMethod} //set conditions
                  type="select"
                  {...level1AssignmentMethodProps}
                  innerRef={level1AssignmentMethodRef}
                >
                  {orderApprovalAssignmentMethodValues &&
                    orderApprovalAssignmentMethodValues.map(val => (
                      <option value={val} key={val}>
                        {translate('iamdentityApp.OrderApprovalAssignmentMethod.' + val)}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              {/* Nom user level 1 */}
              {lvl1Assing === OrderApprovalAssignmentMethod.NOMINAL_USERS && (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orderApprovalLevels.level1NominalUsers" />
                  </Label>
                  <UncontrolledReactSelect
                    control={control}
                    isMulti
                    name="level1NomUser"
                    url={`${usersUrl}/authority/${AUTHORITIES.CAMUNDA_USER}/all`}
                    queryProps={{
                      name: 'employee.fullName',
                      type: 'string',
                    }}
                  />
                </FormGroup>
              )}
              {/* organization unit level 1  */}
              {lvl1Assing === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION ? (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.orderApprovalLevels.level1Job" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="level1Job"
                      url={jobDefinitionsUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.orderApprovalLevels.level1OrganizationUnit" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="level1OrganizationUnit"
                      url={organizationUnitUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              ) : null}
              {/* IAM Role level 1 */}
              {lvl1Assing === OrderApprovalAssignmentMethod.IAM_ROLE && (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orderApprovalLevels.level1IAMRole" />
                  </Label>
                  <Input
                    valid={touchedFields.level1IAMRole && !errors.level1IAMROle} //set conditions
                    invalid={!!errors.level1IAMROle} //set conditions
                    type="select"
                    {...level1IAMRoleProps}
                    innerRef={level1IAMRoleRef}
                  >
                    <option value="" key="0" />
                    {authorities &&
                      Object.values(authorities).map(otherEntity => (
                        <option value={otherEntity} key={otherEntity}>
                          {otherEntity}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              )}
              {/* Final Method 1 */}
              {/* Assign Method  2*/}
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.level2AssignmentMethod" />
                </Label>
                <Input
                  valid={touchedFields.level2AssignmentMethod && lvl2Assing && !errors.level2AssignmentMethod} //set conditions
                  invalid={!!errors.level2AssignmentMethod} //set conditions
                  disabled={verifyIfValueFromGroupOneIsSelected}
                  type="select"
                  {...level2AssignmentMethodProps}
                  innerRef={level2AssignmentMethodRef}
                >
                  <option value={null} key=""></option>
                  {orderApprovalAssignmentMethodValues &&
                    orderApprovalAssignmentMethodValues
                      .filter(val =>
                        lvl1Assing === OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR
                          ? val !== OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR
                          : val
                      )
                      .map(orderApprovalAssignmentMethod => (
                        <option value={orderApprovalAssignmentMethod} key={orderApprovalAssignmentMethod}>
                          {translate('iamdentityApp.OrderApprovalAssignmentMethod.' + orderApprovalAssignmentMethod)}
                        </option>
                      ))}
                </Input>
              </FormGroup>
              {/* Nom user level 2 */}
              {lvl2Assing === OrderApprovalAssignmentMethod.NOMINAL_USERS && (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orderApprovalLevels.level2NominalUsers" />
                  </Label>
                  <UncontrolledReactSelect
                    control={control}
                    isMulti
                    name="level2NomUser"
                    url={`${usersUrl}/authority/${AUTHORITIES.CAMUNDA_USER}/all`}
                    queryProps={{
                      name: 'employee.fullName',
                      type: 'string',
                    }}
                  />
                </FormGroup>
              )}
              {/* organization unit level 2  */}
              {lvl2Assing === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION ? (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.orderApprovalLevels.level2Job" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="level2Job"
                      url={jobDefinitionsUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.orderApprovalLevels.level2OrganizationUnit" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="level2OrganizationUnit"
                      url={organizationUnitUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              ) : null}
              {/*  */}
              {/* IAM Role level 2 */}
              {lvl2Assing === OrderApprovalAssignmentMethod.IAM_ROLE && (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orderApprovalLevels.level2IAMRole" />
                  </Label>
                  <Input
                    valid={touchedFields.level2IAMRole && !errors.level2IAMROle} //set conditions
                    invalid={!!errors.level2IAMROle} //set conditions
                    type="select"
                    {...level2IAMRoleProps}
                    innerRef={level2IAMRoleRef}
                  >
                    <option value="" key="0" />
                    {authorities &&
                      Object.values(authorities).map(otherEntity => (
                        <option value={otherEntity} key={otherEntity}>
                          {otherEntity}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              )}
              {/* Final Method 2 */}
              {/* Assign Method  3*/}
              {lvl2Assing && (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orderApprovalLevels.level3AssignmentMethod" />
                  </Label>
                  <Input
                    valid={touchedFields.level3AssignmentMethod && lvl3Assing && !errors.level3AssignmentMethod} //set conditions
                    invalid={!!errors.level3AssignmentMethod} //set conditions
                    disabled={verifyIfValueFromGroupTwoIsSelected}
                    type="select"
                    {...level3AssignmentMethodProps}
                    innerRef={level3AssignmentMethodRef}
                  >
                    <option value={null} key=""></option>
                    {orderApprovalAssignmentMethodValues &&
                      orderApprovalAssignmentMethodValues
                        .filter(val =>
                          lvl1Assing === OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR ||
                          lvl2Assing === OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR
                            ? val !== OrderApprovalAssignmentMethod.EMPLOYEE_SUPERIOR
                            : val
                        )
                        .map(orderApprovalAssignmentMethod => (
                          <option value={orderApprovalAssignmentMethod} key={orderApprovalAssignmentMethod}>
                            {translate('iamdentityApp.OrderApprovalAssignmentMethod.' + orderApprovalAssignmentMethod)}
                          </option>
                        ))}
                  </Input>
                </FormGroup>
              )}
              {/* Nom user level 3 */}
              {lvl3Assing === OrderApprovalAssignmentMethod.NOMINAL_USERS && (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orderApprovalLevels.level3NominalUsers" />
                  </Label>
                  <UncontrolledReactSelect
                    control={control}
                    isMulti
                    name="level3NomUser"
                    url={`${usersUrl}/authority/${AUTHORITIES.CAMUNDA_USER}/all`}
                    queryProps={{
                      name: 'employee.fullName',
                      type: 'string',
                    }}
                  />
                </FormGroup>
              )}
              {/* organization unit level 3 */}
              {lvl3Assing === OrderApprovalAssignmentMethod.ORG_UNIT_AND_FUNCTION && (
                <React.Fragment>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.orderApprovalLevels.level3Job" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="level3Job"
                      url={jobDefinitionsUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <Translate contentKey="iamdentityApp.orderApprovalLevels.level3OrganizationUnit" />
                    </Label>
                    <PaginatedReactSelect
                      control={control}
                      name="level3OrganizationUnit"
                      url={organizationUnitUrl}
                      queryProps={{
                        name: 'name',
                        type: 'string',
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              )}
              {/* IAM Role level 3 */}
              {lvl3Assing === OrderApprovalAssignmentMethod.IAM_ROLE && (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orderApprovalLevels.level3IAMRole" />
                  </Label>
                  <Input
                    valid={touchedFields.level3IAMRole && !errors.level3IAMROle} //set conditions
                    invalid={!!errors.level3IAMROle} //set conditions
                    type="select"
                    {...level3IAMRoleProps}
                    innerRef={level3IAMRoleRef}
                  >
                    <option value="" key="0" />
                    {authorities &&
                      Object.values(authorities).map(otherEntity => (
                        <option value={otherEntity} key={otherEntity}>
                          {otherEntity}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              )}
              {/* Final Method 3 */}
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.validFrom" />
                </Label>
                <Input
                  min={moment().format(APP_GLOBAL_DATE_FORMAT)}
                  max={validTo?.toString()}
                  valid={touchedFields.validFrom && validFrom && !errors.validFrom}
                  invalid={!!errors.validFrom}
                  readOnly={moment() > moment(orderApprovalLevelsEntity.validFrom)}
                  type="date"
                  {...validFromProps}
                  innerRef={validFromRef}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderApprovalLevels.validTo" />
                </Label>
                <Input
                  min={validFrom?.toString()}
                  valid={touchedFields.validTo && validTo && !errors.validTo}
                  invalid={!!errors.validTo}
                  type="date"
                  {...validToProps}
                  innerRef={validToRef}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderApprovalLevels.riskLevel')}
                id="order-approval-levels-riskLevel"
                name="riskLevel"
                data-cy="riskLevel"
                type="select"
              >
                {riskLevelValues.map(riskLevel => (
                  <option value={riskLevel} key={riskLevel}>
                    {translate('iamdentityApp.RiskLevel.' + riskLevel)}
                  </option>
                ))}
              </ValidatedField>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button
                  color="primary"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                  disabled={updating || checkValidity(orderApprovalLevelsEntity.validTo)}
                >
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrderApprovalLevelsUpdate;
