import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { BPMType } from 'app/shared/model/enumerations/bpm-type.model';
import { IOrderTask, ITask, IToaTask } from 'app/shared/model/task.model';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Spinner } from 'reactstrap';
import ActionsCard from './components/actions-card';
import CommentsCard from './components/comments-card';
import EmployeeCard from './components/employee-card';
import OrderCard from './components/order-card';
import OrderTypeCard from './components/orderType-card';
import TaskCard from './components/task-card';
import './style.scss';
import { getEntity } from './tasks.reducer';
import FormCard from './components/form-card';
import TaskDetailsCard from './components/application-card';

const EditTask = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();

  const navigate = useNavigate();

  const [fullWidth, setFullWidth] = useState(false);
  const [loading, setLoading] = useState(true);

  const taskEntity: IToaTask = useAppSelector(state => state.task.entity);

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  useEffect(() => {
    if (taskEntity && taskEntity.id) {
      setLoading(false);
    }
  }, [taskEntity]);

  const toggleWidth = () => {
    setFullWidth(!fullWidth);
  };

  const renderTaskInfo = (task: ITask, fullWidth: boolean) => {
    if (task.processDefinitionKey == BPMType.Process_Order_Approval) {
      const orderTask = task as IToaTask;
      return (
        <React.Fragment>
          <Row>
            {orderTask ? <TaskCard task={orderTask} /> : null}
            {orderTask?.order ? <OrderCard order={orderTask.order} /> : null}
          </Row>
          <Row>
            {orderTask?.order?.orderType ? (
              <OrderTypeCard orderType={orderTask.order.orderType} taskType={BPMType.Process_Order_Approval} />
            ) : null}
            {orderTask?.order?.employee ? <EmployeeCard employee={orderTask.order.employee} /> : null}
          </Row>
          <Row>
            <ActionsCard task={orderTask} />
            <CommentsCard orderId={orderTask?.order?.id} />
          </Row>
        </React.Fragment>
      );
    } else if (task.processDefinitionKey == BPMType.Process_TOA_Execution) {
      const toaTask = task as IToaTask;
      const classes = ['task-cards', 'd-flex', 'align-items-flex-start', fullWidth ? '' : 'flex-wrap'];
      return (
        <React.Fragment>
          <div className={classes.join(' ')}>
            <Row className="flex-wrap">
              <Row>
                {toaTask ? <TaskCard task={toaTask} /> : null}
                {toaTask?.order ? <OrderCard order={toaTask.order} /> : null}
              </Row>
              <Row>
                {toaTask?.order?.orderType ? (
                  <OrderTypeCard
                    orderType={toaTask.order.orderType}
                    taskType={BPMType.Process_TOA_Execution}
                    employee={toaTask.order.employee}
                    showLinks
                  />
                ) : null}
                {<TaskDetailsCard task={toaTask} showLinks />}
                {/* {toaTask?.order?.employee ? <EmployeeCard employee={toaTask.order.employee} /> : null} */}
              </Row>
              <Row>
                <ActionsCard task={toaTask} />
                <CommentsCard orderId={toaTask?.order?.id} />
              </Row>
            </Row>
            {toaTask.assignee ? (
              <div>
                <FormCard />
              </div>
            ) : null}
          </div>
        </React.Fragment>
      );
    }
  };

  return (
    <div>
      <Offcanvas
        scrollable={true}
        className={fullWidth ? 'full-width' : 'normal-width'}
        direction="end"
        isOpen={true}
        toggle={() => navigate(-1)}
      >
        <OffcanvasHeader toggle={() => navigate(-1)}>
          <FontAwesomeIcon className="hand" onClick={toggleWidth} icon="expand" />
        </OffcanvasHeader>
        <OffcanvasBody className={fullWidth ? 'task-body-full-width' : 'task-body'}>
          {loading ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            renderTaskInfo(taskEntity, fullWidth)
          )}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default EditTask;
