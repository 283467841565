import { APP_TIMESTAMP_FORMAT } from 'app/config/constants';
import { ITask } from 'app/shared/model/task.model';
import moment from 'moment';
import React from 'react';
import { translate } from 'react-jhipster';
import { Badge, Card, CardBody, CardHeader, CardText, CardTitle } from 'reactstrap';
import '../style.scss';

interface ITaskCardProps {
  task: ITask;
  children?: any;
  onClick?: Function;
}

const TaskCard = ({ task, children, onClick }: ITaskCardProps) => {
  const createdDateBadge = <Badge color="success">{moment(task.createTime).format(APP_TIMESTAMP_FORMAT)}</Badge>;
  const isAssigned = task.assignee ? (
    <Badge color="warning">
      {' '}
      {translate(`iamdentityApp.tasks.assiggnedTo`)}: {task.assignee}
    </Badge>
  ) : null;
  const assignedColorBackground = task.assignee ? 'is-assigned' : '';
  const hasClickAction = onClick instanceof Function;
  const hasChildren = !!children;

  return (
    <Card
      className={hasClickAction ? `hand ${assignedColorBackground}` : `${assignedColorBackground}`}
      onClick={() => (hasClickAction ? onClick(task.id) : null)}
    >
      <CardHeader className={hasChildren ? null : 'no-border'}>
        <CardTitle tag="h5">{translate(`iamdentityApp.tasks.names.${task.name}`)}</CardTitle>
        <CardText>
          <span>{createdDateBadge}</span>
          <span>{isAssigned}</span>
        </CardText>
      </CardHeader>
      {hasChildren ? <CardBody className="flex-div">{children}</CardBody> : null}
    </Card>
  );
};

export default TaskCard;
