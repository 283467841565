import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';

export interface IProject {
  id?: number;
  name?: string | null;
  code?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  projectManager?: IEmployee | null;
}

export const defaultValue: Readonly<IProject> = {};
