import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getProfileDefinitions } from 'app/entities/profile-definition/profile-definition.reducer';
import ExpressionBuilder from 'app/shared/components/expression-builder/expression-builder';
import { ValueType } from 'app/shared/components/expression-builder/types';
import { IStandardPolicyRule } from 'app/shared/model/standard-policy-rule.model';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './standard-policy-rule.reducer';

import { apiUrl as profileDefinitionsUrl } from 'app/entities/profile-definition/profile-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { convertReactSelectValuesToEntityIds } from 'app/shared/util/entity-utils';
import { ENTITY_NAME } from 'app/shared/model/standard-policy-rule.model';
import moment from 'moment';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';

export const StandardPolicyRuleUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const standardPolicyRuleEntity: IStandardPolicyRule = useAppSelector(state => state.standardPolicyRule.entity);
  const loading = useAppSelector(state => state.standardPolicyRule.loading);
  const updating = useAppSelector(state => state.standardPolicyRule.updating);
  const updateSuccess = useAppSelector(state => state.standardPolicyRule.updateSuccess);

  const [conditionExpression, setConditionExpression] = useState(null);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProfileDefinitions({}));
  }, []);

  useEffect(() => {
    setConditionExpression(standardPolicyRuleEntity.conditionExpression);
    formReset(defaultValues());
  }, [standardPolicyRuleEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...standardPolicyRuleEntity,
      ...convertReactSelectValuesToEntityIds(values),
      conditionExpression: conditionExpression,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          validFrom: moment().format(APP_GLOBAL_DATE_FORMAT),
          validTo: null,
        }
      : {
          ...standardPolicyRuleEntity,
          profile: standardPolicyRuleEntity?.profile,
        };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm<IStandardPolicyRule>({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchValidFrom = watch('validFrom');
  const watchValidTo = watch('validTo');

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.standardPolicyRule.home.createOrEditLabel" data-cy="StandardPolicyRuleCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.standardPolicyRule.home.createOrEditLabel">Create or edit a StandardPolicyRule</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="standard-policy-rule-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.standardPolicyRule.name')}
                id="standard-policy-rule-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <FormGroup key={`conditionExpression-${id}`}>
                <Label>
                  <Translate contentKey="iamdentityApp.standardPolicyRule.conditionExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={conditionExpression}
                  restrictedValueType={ValueType.BOOLEAN}
                  onChange={expression => setConditionExpression(expression)}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                max={watchValidTo?.toString()}
                label={translate('iamdentityApp.standardPolicyRule.validFrom')}
                id="standard-policy-rule-validFrom"
                name="validFrom"
                data-cy="validFrom"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                register={register}
                min={watchValidFrom?.toString()}
                label={translate('iamdentityApp.standardPolicyRule.validTo')}
                id="standard-policy-rule-validTo"
                name="validTo"
                data-cy="validTo"
                type="date"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.standardPolicyRule.profile" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="profile"
                  url={profileDefinitionsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default StandardPolicyRuleUpdate;
