import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import {
  getEntitiesByObjectType as getCustomPropertiesByObjectType,
  reset as resetCA,
} from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { apiUrl as employeesUrl, createEntity, updateEntity } from 'app/entities/employee/employee.reducer';
import { apiUrl as jobDefinitionsUrl } from 'app/entities/job-definition/job-definition.reducer';
import { apiUrl as locationsUrl } from 'app/entities/location/location.reducer';
import { apiUrl as organizationUnitsUrl } from 'app/entities/organization-unit/organization-unit.reducer';
import { apiUrl as peopleUrl } from 'app/entities/person/person.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { EmployeeStatus } from 'app/shared/model/enumerations/employee-status.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { getEntity, reset } from './employee.reducer';

export const EmployeeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const employeeEntity: IEmployee = useAppSelector(state => state.employee.entity);
  const loading = useAppSelector(state => state.employee.loading);
  const updating = useAppSelector(state => state.employee.updating);
  const updateSuccess = useAppSelector(state => state.employee.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const employeeStatusValues = Object.keys(EmployeeStatus);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  const defaultValues = () => {
    const result = isNew
      ? { contractStartDate: moment().format(APP_GLOBAL_DATE_FORMAT) }
      : {
          employeeStatus: 'ACTIVE',
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, employeeEntity),
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    dispatch(resetCA());
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.EMPLOYEE));
  }, [id]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [employeeEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(employeeEntity, convertReactSelectValuesToEntityIds(values), customAttributeDefinitions),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.employee.home.createOrEditLabel" data-cy="EmployeeCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.employee.home.createOrEditLabel">Create or edit a Employee</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="employee-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.employee.code')}
                id="employee-code"
                name="code"
                readOnly
                data-cy="code"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.employee.contractStartDate')}
                id="employee-contractStartDate"
                name="contractStartDate"
                readOnly
                data-cy="contractStartDate"
                type="date"
                onChange={event => (startDate.current = event.target.value)}
                validate={{ required: true }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.employee.contractEndDate')}
                id="employee-contractEndDate"
                name="contractEndDate"
                readOnly
                data-cy="contractEndDate"
                type="date"
                min={startDate.current}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.employee.employeeStatus')}
                id="employee-employeeStatus"
                name="employeeStatus"
                disabled
                readOnly
                data-cy="employeeStatus"
                type="select"
              >
                {employeeStatusValues.map(employeeStatus => (
                  <option value={employeeStatus} key={employeeStatus}>
                    {translate('iamdentityApp.EmployeeStatus.' + employeeStatus)}
                  </option>
                ))}
              </ValidatedField>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.employee.organizationUnit" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="organizationUnit"
                  url={organizationUnitsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.employee.job" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="job"
                  url={jobDefinitionsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.employee.superior" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="superior"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    type: 'string',
                    criteria: 'nameWithCode',
                  }}
                  isDisabled
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.employee.location" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="location"
                  url={locationsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.employee.person" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="person"
                  url={peopleUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled
                />
              </FormGroup>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  options: cad.selectableOptions,
                  id: `employee-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default EmployeeUpdate;
