import { faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT_RO, APP_GLOBAL_DATETIME_FORMAT, APP_GLOBAL_DATE_FORMAT, APP_GLOBAL_INSTANT_FORMAT } from 'app/config/constants';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useLocation } from 'react-router-dom';
import { Button, Input, UncontrolledTooltip } from 'reactstrap';
import { getFilterPartialColumnsFromQueryString, getQueryParamsIfExists } from './util/filter-utils';
import { useAppSelector } from 'app/config/store';

export type FilterColumnType = 'number' | 'string' | 'date' | 'instant' | 'boolean' | 'custom' | 'select' | 'ignore' | 'date_as_instant';

export interface IFilterColumn {
  name: string;
  type: FilterColumnType;
  criteria?: string;
  value?: any;
  values?: any[];
  hidden?: boolean;
}

export interface ICustomFilter {
  title: string;
  filter: string;
  disabled?: boolean;
  default?: boolean;
}

export interface IFilterProps {
  getEntities: (filter?: any) => any;
  columns: IFilterColumn[];
  customFilter?: ICustomFilter[];
}

const criteria2Symbol = criteria => {
  switch (criteria) {
    case 'equals':
      return '=';
    case 'greaterThan':
      return '>';
    case 'lessThan':
      return '<';
    case 'greaterThanOrEqual': // 'greaterOrEqualThan':
      return '>=';
    case 'lessThanOrEqual':
      return '<=';
    default:
      return '';
  }
};

export const getCriteriaByType = (type: FilterColumnType) => {
  if (type === 'string') return 'contains';
  else if (type === 'number') return 'equals';
  else if (type === 'boolean' || type === 'select') return 'equals';
  else if (type === 'date') return 'equals';
  else if (type === 'instant' || type === 'date_as_instant') return 'greaterThanOrEqual';
  else return '';
};

const getDefaultCustomFilterValue = customFilter => {
  const defaultCustomFilterOption = customFilter && customFilter.filter(option => option.default).shift();
  return defaultCustomFilterOption && defaultCustomFilterOption.filter ? defaultCustomFilterOption.filter : '';
};

export const Filter = (props: IFilterProps) => {
  const location = useLocation();
  const locale = useAppSelector(state => state.locale.currentLocale);

  const initalColumnsState = (firstOpen: Boolean) => {
    const queryString = getQueryParamsIfExists(location.search);
    const partialColumns = getFilterPartialColumnsFromQueryString(queryString);
    return [...props?.columns].map(column => {
      const matchedColumn = partialColumns.find(col => col.name == column.name);
      column.value = firstOpen && column.value ? column.value : firstOpen && matchedColumn?.value ? matchedColumn.value : '';
      column.value = decodeURIComponent(column.value);
      if (column.criteria) {
        column.criteria = column.criteria;
      }
      if (firstOpen && matchedColumn?.criteria) {
        column.criteria = matchedColumn.criteria;
      } else {
        column.criteria = getCriteriaByType(column.type);
      }
      return column;
    });
  };

  const [columns, setColumns] = useState<IFilterColumn[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [customFilter, setCustomFilter] = useState<string>(getDefaultCustomFilterValue(props.customFilter));
  const [touched, setTouched] = useState<boolean>(false);
  const [customShown, setCustomShown] = useState<boolean>(!!props.customFilter);

  const applyFilter = useCallback(
    _.debounce(dFilter => {
      props.getEntities(dFilter.endsWith('&') ? dFilter.slice(0, -1) : dFilter);
    }, 1000),
    []
  );

  const buildAndSetFilter = (dColumns, dCustomFilter) => {
    let newFilter = '';
    dColumns.forEach(column => {
      if (column.criteria?.trim() && column.value) {
        newFilter += column.name + '.' + column.criteria + '=';
        if (column.value instanceof Date) {
          if (column.type === 'instant' || column.type === 'date_as_instant') {
            newFilter += moment(column.value).utc().format(APP_GLOBAL_INSTANT_FORMAT);
          } else {
            newFilter += moment(column.value).format(APP_GLOBAL_DATE_FORMAT);
          }
        } else if (typeof column.value === 'string' && column.value.trim()) {
          newFilter += encodeURIComponent(column.value.trim());
        } else if (column.value && typeof column.value !== 'string') {
          newFilter += column.value;
        }
        newFilter += '&';
      }
    });
    newFilter += dCustomFilter === 'placeholder' ? '' : dCustomFilter;
    setFilter(newFilter);
  };

  useEffect(() => {
    if (touched) {
      applyFilter(filter);
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = initalColumnsState(true);
    setTouched(true);
    setColumns(newColumns);
  }, [props.columns]);

  useEffect(() => {
    buildAndSetFilter(columns, customFilter);
  }, [columns]);

  const handleCriteriaChange = event => {
    handleColumnChange('criteria', event.target.value, event.target.name);
  };

  const handleValueChange = event => {
    handleColumnChange('value', event.target.value, event.target.name);
  };

  const handleColumnChange = (prop, value, columnName) => {
    const elementIndex = columns.findIndex(column => column.name === columnName);
    const newColumns = [...columns].filter((column, index) => index !== elementIndex);
    const newElement = columns[elementIndex];
    newElement[prop] = value;
    if (newElement.type === 'instant' || newElement.type === 'date_as_instant') {
      if (newElement.criteria && (newElement.criteria === 'greaterThanOrEqual' || newElement.criteria === 'lessThan')) {
        newElement.value = newElement.value && moment(newElement.value).startOf('day').toDate();
      } else if (newElement.criteria && (newElement.criteria === 'lessThanOrEqual' || newElement.criteria === 'greaterThan')) {
        newElement.value = newElement.value && moment(newElement.value).endOf('day').toDate();
      }
    }
    newColumns.splice(elementIndex, 0, newElement);
    setColumns(newColumns);
    setTouched(true);
  };

  const handleDateChange = (columnName, value) => {
    handleColumnChange('value', value, columnName);
  };

  const renderColumn = (column: IFilterColumn, focused: boolean) => {
    let valueComponent: JSX.Element | null = null;

    if (column.hidden) return <th />;
    else if (column.type === 'custom') {
      valueComponent = renderCustomFilter();
    }
    let criteriaOptions: string[] = [];
    let criteriaShow = false;

    if (column.type === 'number') {
      criteriaShow = true;
      criteriaOptions = ['equals', 'greaterThan', 'lessThan', 'greaterThanOrEqual', 'lessThanOrEqual'];
      valueComponent = (
        <Input
          style={column.name === 'id' ? { minWidth: '130px' } : {}}
          onChange={handleValueChange}
          name={column.name}
          value={column.value}
          className="value-component"
          inputprops={{ min: 0 }}
          type="number"
          autoFocus={focused}
        />
      );
    } else if (column.type === 'string') {
      criteriaShow = false;
      criteriaOptions = ['contains'];

      valueComponent = (
        <Input
          value={column.value}
          name={column.name}
          onChange={handleValueChange}
          className="value-component"
          autoFocus={focused}
          type="text"
        />
      );
    } else if (column.type === 'date' || column.type === 'date_as_instant') {
      criteriaShow = true;
      criteriaOptions = ['equals', 'greaterThan', 'lessThan', 'greaterThanOrEqual', 'lessThanOrEqual'];
      valueComponent = (
        <Input
          type="date"
          className="value-component"
          value={
            column.value ? (column.type === 'date_as_instant' ? moment(column.value).format(APP_GLOBAL_DATE_FORMAT) : column.value) : ''
          }
          onChange={event => handleDateChange(column.name, event.target.value)}
        />
      );
    } else if (column.type === 'instant') {
      criteriaShow = true;
      criteriaOptions = ['greaterThan', 'lessThan', 'greaterThanOrEqual', 'lessThanOrEqual'];

      valueComponent = (
        <Input
          type="datetime-local"
          className="value-component"
          value={column.value ? moment(column.value).format(APP_GLOBAL_DATETIME_FORMAT) : ''}
          onChange={event => handleDateChange(column.name, event.target.value)}
        />
      );
    } else if (column.type === 'boolean') {
      criteriaShow = false;
      criteriaOptions = ['equals'];
      valueComponent = (
        <Input type="select" onChange={handleValueChange} name={column.name} value={column.value} className="value-component">
          <option value="">{''}</option>
          <option value="true">{translate(`global.yes`)}</option>
          <option value="false">{translate(`global.no`)}</option>
        </Input>
      );
    } else if (column.type === 'select') {
      criteriaShow = false;
      criteriaOptions = ['equals'];
      valueComponent = (
        <Input type="select" onChange={handleValueChange} name={column.name} value={column.value} className="value-component">
          {column.values?.map(option => (
            <option
              key={option['value']}
              value={option['value'] === 'placeholder' ? '' : option['value']}
              // disabled={option['value'] === 'placeholder'}
            >
              {option['label']}
            </option>
          ))}
        </Input>
      );
    } else if (column.type === 'ignore') {
      criteriaShow = false;
      valueComponent = null;
    }

    return (
      <th>
        <div className="flex-div">
          {criteriaShow && (
            <Input
              type="select"
              value={column.criteria}
              onChange={handleCriteriaChange}
              name={column.name}
              className="value-component criteria-component"
            >
              {criteriaOptions.map(criteria => (
                <option key={criteria} value={criteria}>
                  {criteria2Symbol(criteria)}
                </option>
              ))}
            </Input>
          )}
          {valueComponent}
        </div>
      </th>
    );
  };

  const clearFilter = () => {
    setColumns(initalColumnsState(false));
    setCustomFilter('placeholder');
  };

  const handleCustomFilter = event => {
    // apply filter after
    setCustomFilter(event.target.value);
  };

  const renderCustomFilter = () => (
    <Input type="select" onChange={handleCustomFilter} value={customFilter}>
      {props?.customFilter?.map(option => {
        if (option.disabled) {
          return (
            <option value="placeholder" selected disabled>
              {option.title}
            </option>
          );
        } else {
          return <option value={option.filter}>{option.title}</option>;
        }
      })}
    </Input>
  );

  return (
    <tr>
      {columns.map((column, index) => (
        <React.Fragment key={`filter-${index}`}>
          {renderColumn(column, column.name !== 'id' && index === 0 && !touched && filter === '')}
        </React.Fragment>
      ))}
      <th>
        <div className="flex-div justify-end">
          {customShown && customFilter && (
            <React.Fragment>
              <span className="ml-1" />
              {renderCustomFilter()}
            </React.Fragment>
          )}
          <span>
            <Button size="sm" id="filter-button" disabled={!filter} color="default" onClick={clearFilter} className="reset-filter-button">
              <FontAwesomeIcon icon={faFilterCircleXmark} />
            </Button>
          </span>
          <UncontrolledTooltip placement="top" target="filter-button">
            <Translate contentKey="global.clearFilter">Clear Filter</Translate>
          </UncontrolledTooltip>
        </div>
      </th>
    </tr>
  );
};

export default Filter;
