import { IInUserIdentityRole } from 'app/shared/model/in-user-identity-role.model';
import { IUser } from 'app/shared/model/user.model';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IReconSessionUserIdentityRoleSystemsNotInIam {
  id?: number;
  applicationCode?: string | null;
  userBusinessKey?: string | null;
  userName?: string | null;
  roleCode?: string | null;
  processed?: boolean | null;
  comments?: string | null;
  inUserIdentityRole?: IInUserIdentityRole | null;
  processedBy?: IUser | null;
  importSession?: IImportSession | null;
}

export const defaultValue: Readonly<IReconSessionUserIdentityRoleSystemsNotInIam> = {
  processed: false,
};
