import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, getSortState, translate } from 'react-jhipster';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { getEntities, searchEntities } from 'app/entities/orders/orders.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import Filter from 'app/shared/FilterComponent';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IOrder } from 'app/shared/model/order.model';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';

interface IRecentOrders {
  id: number;
}

export const RecentOrders = ({ id }: IRecentOrders) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ordersList: IOrder[] = useAppSelector(state => state.orders.entities);
  const loading = useAppSelector(state => state.orders.loading);
  const totalItems = useAppSelector(state => state.orders.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 5, 'id'), location.search)
  );

  const [search, setSearch] = useState('');
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  useEffect(() => {
    dispatch(getEntities({ page: 0, size: 5, query: `createdById.equals=${id}`, sort: 'scheduleTime,desc' }));
  }, [id]);
  const customQuery = query ? query + `&createdById.equals=${id}` : `createdById.equals=${id}`;

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: customQuery,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: customQuery,
        })
      );
    }
  };

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: customQuery,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  return (
    <Card className="jh-card mt-1">
      <h2 id="orders-heading" data-cy="OrdersHeading">
        <Translate contentKey="iamdentityApp.orders.home.recentOrders">Orders</Translate>
      </h2>
      <div className="table-responsive">
        <Table responsive>
          <thead>
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="iamdentityApp.orders.id">ID</Translate> {handleSortIconsTable('id')}
              </th>
              <th>
                <Translate contentKey="iamdentityApp.orders.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th>
                <Translate contentKey="iamdentityApp.orders.orderType">Order Type</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('status')}>
                <Translate contentKey="iamdentityApp.orders.status">Status</Translate> {handleSortIconsTable('status')}
              </th>
              <th className="hand" onClick={sort('scheduleTime')}>
                <Translate contentKey="iamdentityApp.orders.scheduleTime">Schedule Time</Translate> {handleSortIconsTable('scheduleTime')}
              </th>
              <th className="hand" onClick={sort('executionStartTime')}>
                <Translate contentKey="iamdentityApp.orders.executionStartTime">Execution Start Time</Translate>{' '}
                {handleSortIconsTable('executionStartTime')}
              </th>
              <th className="hand" onClick={sort('executionActualDuration')}>
                <Translate contentKey="iamdentityApp.orders.executionActualDuration">Execution Actual Duration</Translate>{' '}
                {handleSortIconsTable('executionActualDuration')}
              </th>
              <th className="text-right">
                <span>
                  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>
                    <FontAwesomeIcon icon="magnifying-glass" />
                  </Button>
                </span>
                <UncontrolledTooltip placement="top" target="toggle-filter">
                  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>
                </UncontrolledTooltip>
              </th>
            </tr>
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'ignore',
                  },
                  {
                    name: 'employeeNameWithCode',
                    type: 'string',
                  },
                  {
                    name: 'orderType',
                    type: 'string',
                  },
                  {
                    name: 'status',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OrderStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OrderStatus.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'scheduleTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionStartTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionActualDuration',
                    type: 'number',
                  },
                ]}
              />
            )}
          </thead>
          <tbody>
            {ordersList.map((orders, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/orders/${orders.id}`} color="link" size="sm">
                    {orders.id}
                  </Button>
                </td>
                <td>
                  {orders.employee ? (
                    <Link to={`/employee/${orders.employee.id}`}>
                      {orders.employee.code} - {orders.employee.fullName}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{orders.orderType ? <Link to={`/order-type/${orders.orderType.id}`}>{orders.orderType.name}</Link> : ''}</td>
                <td>{orders.status ? translate(`iamdentityApp.OrderStatus.${orders.status}`) : null}</td>
                <td>
                  {orders.scheduleTime ? (
                    <TextFormat type="date" value={orders.scheduleTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orders.executionStartTime ? (
                    <TextFormat type="date" value={orders.executionStartTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>{orders.executionActualDuration}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/orders/${orders.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!ordersList || ordersList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.orders.home.notFound">No Orders found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={ordersList && ordersList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default RecentOrders;
