import dayjs from 'dayjs';

import {
  APP_DATETIME_FORMAT_RO,
  APP_DATETIME_FORMAT_USA,
  APP_DATE_FORMAT_RO,
  APP_DATE_FORMAT_USA,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATE_FORMAT,
} from 'app/config/constants';
import moment from 'moment';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? dayjs(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const convertDateFormat = (locale: string, type: string) => {
  if (locale === 'ro' && type === 'date') {
    return APP_DATE_FORMAT_RO;
  } else if (locale === 'ro' && type === 'instant') {
    return APP_DATETIME_FORMAT_RO;
  } else if (locale === 'en' && type === 'instant') {
    return APP_DATETIME_FORMAT_USA;
  } else {
    return APP_DATE_FORMAT_USA;
  }
};

export const isDateInTheFuture = date => {
  if (!date) {
    return true;
  }
  return moment(date).isAfter(moment());
};
