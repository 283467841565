import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './order-comments.reducer';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Filter from 'app/shared/FilterComponent';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { handleSortIconsTable } from 'app/shared/util/table-icons';

export const OrderComments = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const orderCommentsList = useAppSelector(state => state.orderComments.entities);
  const loading = useAppSelector(state => state.orderComments.loading);
  const totalItems = useAppSelector(state => state.orderComments.totalItems);

  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  return (
    <Card className="jh-card">
      <h2 id="order-comments-heading" data-cy="OrderCommentsHeading">
        <Translate contentKey="iamdentityApp.orderComments.home.title">Order Comments</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="iamdentityApp.orderComments.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} /> <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="iamdentityApp.orderComments.id">ID</Translate> {handleSortIconsTable('id')}
              </th>
              <th className="hand" onClick={sort('description')}>
                <Translate contentKey="iamdentityApp.orderComments.description">Description</Translate>{' '}
                {handleSortIconsTable('description')}
              </th>
              <th className="hand" onClick={sort('order')}>
                <Translate contentKey="iamdentityApp.orderComments.order">Order</Translate> {handleSortIconsTable('order')}
              </th>
              <th className="text-right">
                <span>
                  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>
                    <FontAwesomeIcon icon="magnifying-glass" />
                  </Button>
                </span>
                <UncontrolledTooltip placement="top" target="toggle-filter">
                  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>
                </UncontrolledTooltip>
              </th>
            </tr>
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'ignore',
                  },
                  {
                    name: 'description',
                    type: 'string',
                  },
                  {
                    name: 'orders',
                    type: 'string',
                  },
                ]}
              />
            )}
          </thead>
          <tbody>
            {orderCommentsList.map((orderComments, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/order-comments/${orderComments.id}`} color="link" size="sm">
                    {orderComments.id}
                  </Button>
                </td>
                <td>{orderComments.description}</td>
                <td>{orderComments.order ? <Link to={`/orders/${orderComments.order.id}`}>{orderComments.order.id}</Link> : ''}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/order-comments/${orderComments.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
                    </Button>
                    {/* <Button
                        tag={Link}
                        to={`/order-comments/${orderComments.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/order-comments/${orderComments.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!orderCommentsList || orderCommentsList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.inEmployeeDelegation.home.notFound">No Order Comments found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={orderCommentsList && orderCommentsList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default OrderComments;
