import './home.scss';

import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { Alert, Button, Card, Col, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getLoginUrl, REDIRECT_URL } from 'app/shared/util/url-utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RecentOrders from './recent-orders';
import {
  executeHrResyncParams,
  executeSprResync,
  checkAdminSprEmployeeProfileUrl,
  sprEvaluateEmployeeProfile,
  simulateResync,
  simulateResyncResultsUrl,
  checkCanSimulateResyncUrl,
} from 'app/entities/orders/orders.reducer';
import axios from 'axios';
import { useQuery } from 'react-query';
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import {
  ICheckAdminEmployeeProfile,
  ICheckCanSimulateResync,
  ISimulateResyncResultsList,
  checkSimulateProcessStatus,
} from './simulare-spr-resync.model';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

const SimulateSprResync = React.lazy(() => import('./simulate-spr-resync'));

export const Home = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const loading = useAppSelector(state => state.orders.loading);
  const updateSuccess = useAppSelector(state => state.orders.updateSuccess);

  const [toggle, setToggle] = useState(false);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  useEffect(() => {
    if (updateSuccess) {
      refetchCheckAdminEmployeeProfile();
      refetchCheckCanSimulateResync();
      refetch();
    }
  }, [updateSuccess]);

  const {
    data: checkAdminEmployeeProfile,
    isSuccess: isSuccessCheckAdminEmployeeProfile,
    refetch: refetchCheckAdminEmployeeProfile,
  } = useQuery('checkAdminSprEmployeeProfile', async () => axios.get<ICheckAdminEmployeeProfile>(checkAdminSprEmployeeProfileUrl), {
    refetchOnWindowFocus: false,
    enabled: !!account?.login,
  });

  const {
    data: checkCanSimulateResync,
    isSuccess: isSuccessCheckCanSimulateResync,
    refetch: refetchCheckCanSimulateResync,
  } = useQuery('checkCanSimulateResync', async () => axios.get<ICheckCanSimulateResync>(checkCanSimulateResyncUrl), {
    refetchOnWindowFocus: false,
    enabled: !!account?.login,
  });

  const {
    data: results,
    isSuccess,
    isLoading,
    refetch,
  } = useQuery('simulateResyncResults', async () => axios.get<ISimulateResyncResultsList>(simulateResyncResultsUrl), {
    refetchOnWindowFocus: false,
    enabled: !!account?.login,
  });

  const hrResyncParams = () => {
    dispatch(executeHrResyncParams());
  };
  const sprResync = () => {
    dispatch(executeSprResync());
  };

  const handleSprEvaluateEmployeeProfile = () => {
    dispatch(sprEvaluateEmployeeProfile());
  };

  const handleSimulateResync = () => {
    dispatch(simulateResync());
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <React.Fragment>
      <Card className="jh-card">
        <Row>
          <Col md="3" className="pad">
            <span className="hipster rounded" />
          </Col>
          <Col md="9">
            <h2>
              <Translate contentKey="home.title">Welcome, IAMdentity!</Translate>
            </h2>
            <p className="lead">
              <Translate contentKey="home.subtitle">This is your homepage</Translate>
            </p>
            {account?.login ? (
              <div>
                <Alert color="success">
                  <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                    You are logged in as user {account.login}.
                  </Translate>
                </Alert>
                <div>
                  <div className="d-flex flex-wrap">
                    <Link
                      to="/orders/new"
                      className="mr-1 my-1 btn btn-primary jh-create-entity"
                      id="jh-create-entity"
                      data-cy="entityCreateButton"
                    >
                      <FontAwesomeIcon icon="plus" />
                      &nbsp;
                      <Translate contentKey="iamdentityApp.orders.home.createLabelButton">Create new Order</Translate>
                    </Link>

                    <Button
                      onClick={hrResyncParams}
                      className="mr-1 my-1 btn btn-primary jh-hr-resync-params"
                      id="jh-hr-resync-params"
                      data-cy="hrResyncParamsButton"
                      disabled={loading}
                    >
                      <FontAwesomeIcon icon="sync" />
                      &nbsp;
                      <Translate contentKey="iamdentityApp.orders.home.syncExtHrData">Sync with external HR Data</Translate>
                    </Button>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Button
                      onClick={sprResync}
                      className="mr-1 my-1 btn btn-primary jh-spr-resync"
                      id="jh-spr-resync"
                      data-cy="sprResyncButton"
                      disabled={loading}
                    >
                      <FontAwesomeIcon icon="sync" />
                      &nbsp;
                      <Translate contentKey="iamdentityApp.orders.home.syncExtSpr">Sync with external Target System</Translate>
                    </Button>
                    {isSuccessCheckAdminEmployeeProfile &&
                      checkAdminEmployeeProfile?.data?.value &&
                      !(checkAdminEmployeeProfile?.data?.message === checkSimulateProcessStatus.PROCESS_IN_PROGRESS) && (
                        <Button
                          onClick={handleSprEvaluateEmployeeProfile}
                          className="mr-1 my-1 btn btn-primary jh-spr-resync"
                          id="do-initial-load"
                          data-cy="doInitialLoad"
                          disabled={loading}
                        >
                          <FontAwesomeIcon icon="sync" />
                          &nbsp;
                          <Translate contentKey="iamdentityApp.orders.home.sprEvaluateEmployeeProfile">
                            Sync Evaluate Employee Profile
                          </Translate>
                        </Button>
                      )}
                    {isSuccessCheckCanSimulateResync &&
                      checkCanSimulateResync?.data?.value &&
                      !(checkCanSimulateResync?.data?.message === checkSimulateProcessStatus.PROCESS_IN_PROGRESS) && (
                        <Button
                          onClick={handleSimulateResync}
                          className="mr-1 my-1 btn btn-primary"
                          id="simulate-spr-resync"
                          data-cy="simulateSprResync"
                          disabled={loading}
                        >
                          <FontAwesomeIcon icon="sync" />
                          &nbsp;
                          <Translate contentKey="home.simulateResyncResults.title">
                            Simulate Resync with Actual System Access Rights
                          </Translate>
                        </Button>
                      )}
                    {isSuccess && isAdmin && results?.data && (
                      <Button
                        onClick={handleToggle}
                        className="mr-1 my-1 btn btn-primary"
                        color="warning"
                        id="simulate-spr-resync"
                        data-cy="simulateSprResync"
                      >
                        {!toggle ? <FontAwesomeIcon icon={faToggleOff} /> : <FontAwesomeIcon icon={faToggleOn} />}
                        &nbsp;
                        <Translate contentKey="home.simulateResyncResults.simulateResyncResultsList">
                          Simulate Resync with Actual System Access Rights
                        </Translate>
                      </Button>
                    )}
                  </div>

                  <RecentOrders id={account.id} />
                </div>
                <div>
                  {toggle && (
                    <React.Suspense
                      fallback={
                        <div className="entity-spinner-container">
                          <Spinner color="primary">Loading...</Spinner>
                        </div>
                      }
                    >
                      <SimulateSprResync results={results?.data} isSuccess={isSuccess} isLoading={isLoading} />
                    </React.Suspense>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <Alert color="warning">
                  <Translate contentKey="global.messages.info.authenticated.prefix">If you want to </Translate>

                  <a href={getLoginUrl()} className="alert-link">
                    <Translate contentKey="global.messages.info.authenticated.link">sign in</Translate>
                  </a>
                  <Translate contentKey="global.messages.info.authenticated.suffix">
                    , you can try the default accounts:
                    <br />- Administrator (login=&quot;admin&quot; and password=&quot;admin&quot;)
                    <br />- User (login=&quot;user&quot; and password=&quot;user&quot;).
                  </Translate>
                </Alert>
              </div>
            )}
          </Col>
        </Row>
      </Card>
      <div className="mt-1">
        <p>
          <Translate contentKey="home.question">If you have any question on IAMdentity:</Translate>
        </p>

        <ul>
          <li>
            <a href="https://advisority.com/" target="_blank" className="text-white" rel="noopener noreferrer">
              <Translate contentKey="home.link.homepage">IAMdentity homepage</Translate>
            </a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Home;
