import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './standard-policy-rule.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const StandardPolicyRuleDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const standardPolicyRuleEntity = useAppSelector(state => state.standardPolicyRule.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="standardPolicyRuleDetailsHeading">
              <Translate contentKey="iamdentityApp.standardPolicyRule.detail.title">StandardPolicyRule</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{standardPolicyRuleEntity.id}</dd>
              <dt>
                <span id="name">
                  <Translate contentKey="iamdentityApp.standardPolicyRule.name">Name</Translate>
                </span>
              </dt>
              <dd>{standardPolicyRuleEntity.name}</dd>
              <dt>
                <span id="conditionExpression">
                  <Translate contentKey="iamdentityApp.standardPolicyRule.conditionExpression">Condition Expression</Translate>
                </span>
              </dt>
              <dd>
                {standardPolicyRuleEntity.conditionExpression ? (
                  <CompactExpressionBuilder tree={standardPolicyRuleEntity.conditionExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="priority">
                  <Translate contentKey="iamdentityApp.standardPolicyRule.priority">Priority</Translate>
                </span>
              </dt>
              <dd>{standardPolicyRuleEntity.priority}</dd>
              <dt>
                <span id="validFrom">
                  <Translate contentKey="iamdentityApp.standardPolicyRule.validFrom">Valid From</Translate>
                </span>
              </dt>
              <dd>
                {standardPolicyRuleEntity.validFrom ? (
                  <TextFormat value={standardPolicyRuleEntity.validFrom} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="validTo">
                  <Translate contentKey="iamdentityApp.standardPolicyRule.validTo">Valid To</Translate>
                </span>
              </dt>
              <dd>
                {standardPolicyRuleEntity.validTo ? (
                  <TextFormat value={standardPolicyRuleEntity.validTo} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.standardPolicyRule.profile">Profile</Translate>
              </dt>
              <dd>{standardPolicyRuleEntity.profile ? standardPolicyRuleEntity.profile.name : ''}</dd>
            </dl>
            <div className="btn-group flex-btn-group-container">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              <Button tag={Link} to={`/standard-policy-rule/${standardPolicyRuleEntity.id}/edit`} color="primary">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.STANDARD_POLICY_RULE} />
    </React.Fragment>
  );
};

export default StandardPolicyRuleDetail;
