import { RuleAppliedTo } from 'app/shared/model/enumerations/rule-applied-to.model';

export interface IRiskRule {
  id?: number;
  name?: string | null;
  description?: string | null;
  riskConditionExpression?: string | null;
  riskResultExpression?: string | null;
  appliedTo?: RuleAppliedTo | null;
  lastUrl?: string | null;
}

export const defaultValue: Readonly<IRiskRule> = {};

export const ENTITY_NAME = 'RiskRule';
