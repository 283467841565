import dayjs from 'dayjs';
import { IOrderType } from 'app/shared/model/order-type.model';
import { IOrganizationUnit } from 'app/shared/model/organization-unit.model';
import { IJobDefinition } from 'app/shared/model/job-definition.model';
import { OrderApprovalAssignmentMethod } from 'app/shared/model/enumerations/order-approval-assignment-method.model';
import { RiskLevel } from 'app/shared/model/enumerations/risk-level.model';
import { IUserWithEmployee } from './user.model';

export interface IOrderApprovalLevels {
  id?: number;
  name?: string | null;
  priority?: number;
  selectionConditionExpression?: string | null;
  level1AssignmentMethod?: OrderApprovalAssignmentMethod | null;
  level1NominalUsers?: string | null;
  level1NominalUsersList?: IUserWithEmployee[] | null;
  level2AssignmentMethod?: OrderApprovalAssignmentMethod | null;
  level2NominalUsers?: string | null;
  level2NominalUsersList?: IUserWithEmployee[] | null;
  level3AssignmentMethod?: OrderApprovalAssignmentMethod | null;
  level3NominalUsers?: string | null;
  level3NominalUsersList?: IUserWithEmployee[] | null;
  validFrom?: string;
  validTo?: string | null;
  orderType?: IOrderType | null;
  level1OrganizationUnit?: IOrganizationUnit | null;
  level2OrganizationUnit?: IOrganizationUnit | null;
  level3OrganizationUnit?: IOrganizationUnit | null;
  level1Job?: IJobDefinition | null;
  level2Job?: IJobDefinition | null;
  level3Job?: IJobDefinition | null;
  level1IAMRole?: { name?: string } | null;
  level2IAMRole?: { name?: string } | null;
  level3IAMRole?: { name?: string } | null;
  riskLevel?: RiskLevel | null;
}

export const defaultValue: Readonly<IOrderApprovalLevels> = {};

export const ENTITY_NAME = 'OrderApprovalLevels';
