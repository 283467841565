import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, updateEntity, resetEntity } from './application-resource.reducer';

import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';
import { IApplicationResource } from 'app/shared/model/application-resource.model';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { IApplication } from 'app/shared/model/application.model';

export const ApplicationResourceUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState(buildSearchParamsObject(searchParams));

  const { applicationResourceId: id } = useParams<'applicationResourceId'>();
  const isNew = id === undefined;

  const applicationResourceEntity: IApplicationResource = useAppSelector(state => state.applicationResource.entity);
  const loading = useAppSelector(state => state.applicationResource.loading);
  const updating = useAppSelector(state => state.applicationResource.updating);
  const updateSuccess = useAppSelector(state => state.applicationResource.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.APPLICATION_RESOURCE));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [applicationResourceEntity]);

  const saveEntity = values => {
    const entity = {
      ...applicationResourceEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        applicationResourceEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment().format(APP_GLOBAL_DATE_FORMAT),
          application: searchParamsObject['application']
            ? { id: searchParamsObject['application'].id, name: searchParamsObject['application'].name }
            : '',
        }
      : {
          ...applicationResourceEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, applicationResourceEntity),
          application: applicationResourceEntity?.application,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });
  const watchApplication: IApplication = watch('application');
  const watchStartDate: string = watch('startDate');

  const minEndDate = () => {
    if (searchParamsObject['application']?.startDate && searchParamsObject['application'].startDate >= watchStartDate) {
      return searchParamsObject['application'].startDate;
    }

    if (watchApplication?.startDate && watchApplication.startDate >= watchStartDate) {
      return watchApplication.startDate;
    }

    return watchStartDate;
  };
  return (
    <Card className="jh-card p-3">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.applicationResource.home.createOrEditLabel" data-cy="ApplicationResourceCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.applicationResource.home.createOrEditLabel">
              Create or edit a ApplicationResource
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="application-resource-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                required
                label={`*${translate('iamdentityApp.applicationResource.name')}`}
                id="application-resource-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                register={register}
                required
                label={`*${translate('iamdentityApp.applicationResource.code')}`}
                id="application-resource-code"
                name="code"
                data-cy="code"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.applicationResource.description')}
                id="application-resource-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                register={register}
                label={`*${translate('iamdentityApp.applicationResource.startDate')}`}
                id="application-resource-startDate"
                name="startDate"
                data-cy="startDate"
                type="date"
                onChange={event => (startDate.current = event.target.value)}
                min={
                  searchParamsObject['application']?.startDate
                    ? searchParamsObject['application'].startDate
                    : watchApplication?.startDate
                    ? watchApplication?.startDate
                    : startDate
                }
                max={
                  searchParamsObject['application']?.endDate
                    ? searchParamsObject['application'].endDate
                    : watchApplication?.endDate
                    ? watchApplication.endDate
                    : null
                }
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.applicationResource.endDate')}
                id="application-resource-endDate"
                name="endDate"
                data-cy="endDate"
                type="date"
                min={minEndDate()}
                max={
                  searchParamsObject['application']?.endDate
                    ? searchParamsObject['application'].endDate
                    : watchApplication?.endDate
                    ? watchApplication.endDate
                    : null
                }
              />
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.applicationResource.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  validate={{ required: true }}
                  isDisabled={searchParamsObject['application'] ? true : false}
                  url={applicationUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  options: cad.selectableOptions,
                  id: `application-resource-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ApplicationResourceUpdate;
