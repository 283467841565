import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ExpressionBuilder from 'app/shared/components/expression-builder/expression-builder';
import { IExpressionEntity } from 'app/shared/model/expression-entity.model';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { createEntity, getEntity, resetEntity, updateEntity } from './profile-specific-permission.reducer';

import { ENTITY_NAME } from 'app/shared/model/profile-specific-permission.model';

import { apiUrl as profileUserIdentitiesURL } from 'app/entities/profile-user-identity/profile-user-identity.reducer';
import { apiByAppIdUrl, apiUrl as permissionDefinitionUrl } from 'app/entities/permission-definition/permission-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { ValueType } from 'app/shared/components/expression-builder/types';

interface ISearchParamsObject {
  profileUserIdentity?: { id: number };
  applicationId?: number;
}

export const ProfileSpecificPermissionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));

  const applicationId = searchParamsObject?.applicationId;

  const { profileSpecificPermissionId: id } = useParams<'profileSpecificPermissionId'>();
  const isNew = id === undefined;

  const profileSpecificPermissionEntity = useAppSelector(state => state.profileSpecificPermission.entity);
  const loading = useAppSelector(state => state.profileSpecificPermission.loading);
  const updating = useAppSelector(state => state.profileSpecificPermission.updating);
  const updateSuccess = useAppSelector(state => state.profileSpecificPermission.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');

  const handleClose = () => {
    navigate(-1);
  };

  const [descriptionExpresion, setDescriptionExpression] = useState(null);
  const [nameExpression, setNameExpression] = useState(null);
  const [permissionDefinitionExpression, setPermissionDefinitionExpression] = useState(null);

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_SPECIFIC_PERMISSION));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    setDescriptionExpression(profileSpecificPermissionEntity.descriptionExpresion);
    setNameExpression(profileSpecificPermissionEntity.nameExpression);
    setPermissionDefinitionExpression(profileSpecificPermissionEntity.permissionDefinitionExpression);
    formReset(defaultValues());
  }, [profileSpecificPermissionEntity]);

  const saveEntity = values => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);

    const entity = {
      ...profileSpecificPermissionEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        profileSpecificPermissionEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
      descriptionExpresion: descriptionExpresion,
      nameExpression: nameExpression,
      permissionDefinitionExpression: permissionDefinitionExpression,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          // startDate: moment().format(APP_LOCAL_DATE_FORMAT)
          startDate: displayDefaultDateTime(),
          endDate: displayDefaultDateTime(),
          ...searchParamsObject,
        }
      : {
          ...profileSpecificPermissionEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, profileSpecificPermissionEntity),

          startDate: convertDateTimeFromServer(profileSpecificPermissionEntity.startDate),
          endDate: convertDateTimeFromServer(profileSpecificPermissionEntity.endDate),
          profileUserIdentity: profileSpecificPermissionEntity?.profileUserIdentity,
          permissionDefinition: profileSpecificPermissionEntity?.permissionDefinition,
          ...searchParamsObject,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  return (
    <Card className="jh-card pad-1">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.profileSpecificPermission.home.createOrEditLabel" data-cy="ProfileSpecificPermissionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.profileSpecificPermission.home.createOrEditLabel">
              Create or edit a ProfileSpecificPermission
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="profile-specific-permission-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.profileUserIdentity" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="profileUserIdentity"
                  isDisabled={!!searchParamsObject?.profileUserIdentity}
                  url={profileUserIdentitiesURL}
                  queryProps={{
                    name: 'profileDefinitionName',
                    secondName: 'period',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.permissionDefinition" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="permissionDefinition"
                  isDisabled={!applicationId}
                  url={!applicationId ? `${apiByAppIdUrl}?applicationId=${applicationId}` : permissionDefinitionUrl}
                  isPageable={!!applicationId ? false : true}
                  validate={{ required: true }}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.descriptionExpresion" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={descriptionExpresion}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setDescriptionExpression(expression)}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.profileSpecificPermission.startDate')}
                id="profile-specific-permission-startDate"
                name="startDate"
                data-cy="startDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                onChange={event => (startDate.current = event.target.value)}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.profileSpecificPermission.endDate')}
                id="profile-specific-permission-endDate"
                name="endDate"
                data-cy="endDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                min={startDate.current}
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.nameExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={nameExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setNameExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileSpecificPermission.permissionDefinitionExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={permissionDefinitionExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setPermissionDefinitionExpression(expression)}
                />
              </FormGroup>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  options: cad.selectableOptions,
                  id: `profile-definition-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileSpecificPermissionUpdate;
