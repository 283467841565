import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './employee-delegation.reducer';
import { createEntity as createOrder } from 'app/entities/orders/orders.reducer';
import { IEmployee } from 'app/shared/model/employee.model';
import { IOrderType } from 'app/shared/model/order-type.model';
import { ICreateOrder, IOrder } from 'app/shared/model/order.model';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import moment from 'moment';
import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { IEmployeeDelegation } from 'app/shared/model/employee-delegation.model';

export const EmployeeDelegationRevokeDialog = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(id));
    setLoadModal(true);
  }, []);

  const employeeDelegationEntity = useAppSelector(state => state.employeeDelegation.entity);
  const { updateSuccess, entity: order } = useAppSelector(state => state.orders);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (updateSuccess && order && order.id) {
      navigate(`/orders/${order.id}`);
      setLoadModal(false);
    }
  }, [updateSuccess, order]);

  const confirmRevoke = () => {
    const employee: IEmployee = {
      id: employeeDelegationEntity.employee.id,
    };
    const orderType: IOrderType = {
      id: 26,
    };
    const orderEntity: IOrder = {
      employee,
      orderType,
      scheduleTime: convertDateTimeToServer(moment().format(APP_LOCAL_DATETIME_FORMAT)),
    };
    const employeeDelegationToCancel: IEmployeeDelegation = {
      id: employeeDelegationEntity.id,
    };
    const entity: ICreateOrder = {
      order: orderEntity,
      employeeDelegation: employeeDelegationToCancel,
    };
    dispatch(createOrder(entity));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="employeeDelegationRevokeDialogHeading">
        <Translate contentKey="entity.revoke.title">Confirm revoke operation</Translate>
      </ModalHeader>
      <ModalBody id="iamdentityApp.employeeDelegation.revoke.question">
        <Translate contentKey="iamdentityApp.employeeDelegation.revoke.question" interpolate={{ id: employeeDelegationEntity.id }}>
          Are you sure you want to revoke this EmployeeDelegation?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-revoke-employeeDelegation" data-cy="entityConfirmRevokeButton" color="danger" onClick={confirmRevoke}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.revoke">Revoke</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EmployeeDelegationRevokeDialog;
